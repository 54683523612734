import {gql} from '@apollo/client';

export const SUBMIT_QUESTIONNAIRE_DOCUMENT = gql`
  mutation submitQuestionnaireDocument(
    $checksum: String
    $contentType: String!
    $documentType: DocumentTypeEnum!
    $expiryDate: Date
    $fileSizeBytes: Int
    $number: String
    $objectName: String!
    $questionnaireId: ID
    $state: String
    $token: ID
  ) {
    submitQuestionnaireDocument(
      checksum: $checksum
      contentType: $contentType
      documentType: $documentType
      expiryDate: $expiryDate
      fileSizeBytes: $fileSizeBytes
      number: $number
      objectName: $objectName
      questionnaireId: $questionnaireId
      state: $state
      token: $token
    ) {
      questionnaire {
        contractorLicense {
          contentType
          contractorId
          createdAt
          documentType
          fileSizeBytes
          id
          number
          objectName
          source
          updatedAt
          rejectedAt
          rejectedBySystem
          rejectionDetails
          rejectionReason
        }
        contractorGeneralLiabilityInsurance {
          contentType
          contractorId
          createdAt
          documentType
          fileSizeBytes
          id
          objectName
          source
          updatedAt
          rejectedAt
          rejectedBySystem
          rejectionDetails
          rejectionReason
        }
        contractorWorkersCompInsurance {
          contentType
          contractorId
          createdAt
          documentType
          fileSizeBytes
          id
          objectName
          source
          updatedAt
          rejectedAt
          rejectedBySystem
          rejectionDetails
          rejectionReason
        }
      }
    }
  }
`;
