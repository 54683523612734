import styled from '@emotion/styled/macro';
import {Flex, Text} from 'rebass';

import {fadeBlue} from '@renofi/utils/src/colors';

import {ReactComponent as CloseIcon} from './close-icon.svg';

export const Wrapper = styled(Flex)(
  {
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
    marginBottom: '10px',
  },
  ({color, css}) => ({
    background: color ? color : fadeBlue,
    padding: '6px 12px',
    height: '31px',
    ...css,
  }),
);

export const StyledText = styled(Text)({
  color: 'black',
  lineHeight: '17px',
  height: 15,
  margin: '0 10px 0 0px',
});

export const IconWrapper = styled(CloseIcon)({
  cursor: 'pointer',
});
