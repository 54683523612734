import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {fadeBlue} from '@renofi/utils/src/colors';
import {mediaQuery} from '@renofi/utils';

export const SearchWrapper = styled(Box)({
  position: 'relative',
});

export const CloseButtonWrapper = styled(Box)({
  position: 'absolute',
  top: 'calc(50% - 10px)',
  right: 20,
  zIndex: 1001,
});

export const OptionsWrapper = styled(Box)(
  {
    boxShadow: '0 0 12px 8px #00000010',
    borderRadius: 8,
    background: 'white',
    width: '100%',
    zIndex: 1001,
  },
  ({floating}) => ({
    position: floating ? 'absolute' : 'relative',
  }),
);

export const OptionWrapper = styled(Box)(
  mediaQuery({
    border: 'solid 1px #EDEDED',
    borderBottom: 'none',
    padding: ['16px 18px 12px 16px', '16px 25px 12px 25px'],
  }),
  ({disabled, noRadius}) => ({
    '&:first-of-type': {
      borderRadius: noRadius ? 0 : '8px 8px 0 0',
    },
    '&:last-of-type': {
      borderRadius: noRadius ? 0 : '0 0 8px 8px',
    },
    '&:first-of-type&:last-of-type': {
      borderRadius: noRadius ? 0 : 8,
    },
    ...(disabled
      ? {}
      : {
          cursor: 'pointer',
          '&:hover': {
            background: fadeBlue,
          },
        }),
  }),
);

export const LoaderWrapper = styled(Box)({
  position: 'absolute',
  top: -8,
  right: 40,
});

export const ValuesWrapper = styled(Box)(
  mediaQuery({
    display: 'grid',
    gridTemplateColumns: ['100%', '24% 16% 30% 30%'],
    gridGap: 20,
    marginRight: [0, 60],
  }),
);
