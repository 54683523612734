import {gql} from '@apollo/client';

export default gql`
  query validateUrlSlug($urlSlug: String!) {
    validateUrlSlug(urlSlug: $urlSlug) {
      reason
      valid
    }
  }
`;
