import styled from '@emotion/styled/macro';
import {Flex, Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const ModalWrapper = styled(Flex)(
  mediaQuery({
    display: 'block',
    position: 'fixed',
    right: 20,
    bottom: [100, 60],
    width: 300,
    padding: 20,
    borderRadius: 16,
    backgroundColor: '#fff',
    filter: 'drop-shadow(0px 10px 40px rgba(0,0,0,0.12))',
    zIndex: 100000,
  }),
);

export const Small = styled(Text)(
  mediaQuery({
    fontSize: 13,
    padding: '20px 0',
  }),
);
