import {useEffect} from 'react';

import {useScreenSize} from '../';

export default function useAutofocus(autofocus, ref, value) {
  const {isDesktop} = useScreenSize();
  useEffect(() => {
    if (isDesktop && autofocus && !value) {
      setTimeout(() => {
        ref?.current?.focus();
        //ref?.current?.setSelectionRange(0, 0); move cursor to the front if needed
      }, 700);
    }
  }, [ref]);
}
