import {gql} from '@apollo/client';

export const QUESTIONNAIRE_SIGNED_URL = gql`
  query questionnaireSignedUrl(
    $accessTo: AccessToForTokenEnum
    $expires: Int
    $objectName: String!
    $questionnaireId: ID
    $token: String
  ) {
    questionnaireSignedUrl(
      accessTo: $accessTo
      expires: $expires
      objectName: $objectName
      questionnaireId: $questionnaireId
      token: $token
    ) {
      url
    }
  }
`;
