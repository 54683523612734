export default ({id, offset}) => {
  let textArea = document.getElementById(id);

  if (!textArea) return;

  const calculateHeight = (textArea) => {
    const style = window.getComputedStyle
      ? window.getComputedStyle(textArea)
      : textArea.currentStyle;
    const taLineHeight = parseInt(style.lineHeight, 10);
    const taHeight = calculateContentHeight(textArea, taLineHeight);
    return Math.ceil(taHeight / taLineHeight);
  };

  const calculateContentHeight = (textArea, scanAmount) => {
    const origHeight = textArea.style.height;
    const scrollHeight = textArea.scrollHeight;
    const overflow = textArea.style.overflow;
    let height = textArea.offsetHeight;
    // only bother if the ta is bigger than content
    if (height >= scrollHeight) {
      // check that our browser supports changing dimension
      // calculations mid-way through a function call...
      textArea.style.height = height + scanAmount + 'px';
      // because the scrollbar can cause calculation problems
      textArea.style.overflow = 'hidden';
      // by checking that scrollHeight has updated
      if (scrollHeight < textArea.scrollHeight) {
        // now try and scan the ta's height downwards
        // until scrollHeight becomes larger than height
        while (textArea.offsetHeight >= textArea.scrollHeight) {
          textArea.style.height = (height -= scanAmount) + 'px';
        }
        // be more specific to get the exact height
        while (textArea.offsetHeight < textArea.scrollHeight) {
          textArea.style.height = height++ + 'px';
        }
        // reset the ta back to it is original height
        textArea.style.height = origHeight;
        // put the overflow back
        textArea.style.overflow = overflow;
        return height;
      }
    } else {
      return scrollHeight;
    }
  };

  const height = calculateHeight(textArea);

  return height - offset;
};
