import {gql} from '@apollo/client';

import {contractor} from '../../partials';

export default gql`
  mutation completeContractorRegistration($contact: ContractorContactInputObject!, $contractor: ContractorInputObject!) {
    completeContractorRegistration(contact: $contact, contractor: $contractor) {
      contractor {
        ${contractor}
      }
    }
  }
`;
