import {gql} from '@apollo/client';

export default gql`
  mutation requestFinancingOnClientBehalf(
    $client: ContractorClientInputObject!
    $contractorId: ID!
  ) {
    requestFinancingOnClientBehalf(
      client: $client
      contractorId: $contractorId
    ) {
      success
    }
  }
`;
