import {gql} from '@apollo/client';

export default gql`
  mutation updateContractorProjectDocument(
    $contractorId: ID!
    $documentId: ID!
    $documentType: DocumentType!
  ) {
    updateContractorProjectDocument(
      contractorId: $contractorId
      documentId: $documentId
      documentType: $documentType
    ) {
      document {
        checksum
        addedBy {
          id
          email
        }
        id
        objectName
        source
        fileName
      }
    }
  }
`;
