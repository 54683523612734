import styled from '@emotion/styled';
import {keyframes} from '@emotion/react';
import {Flex} from 'rebass';

import {green, yellow, blue, red} from '@renofi/utils/src/colors';

import {MODAL_ZINDEX} from '../Modal';

export const NOTIFICATION_COLOR_SCHEME = {
  error: {
    backgroundColor: red,
    color: 'white',
  },
  info: {
    backgroundColor: blue,
    color: 'white',
  },
  warning: {
    backgroundColor: yellow,
    color: 'white',
  },
  success: {
    backgroundColor: green,
    color: 'white',
  },
};

export const slideAlertIn = keyframes`
  from {
    transform: translate3d(0, 60px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

export const Notification = styled(Flex)(
  {
    width: '100%',
    lineHeight: '18px',
    animation: `${slideAlertIn} 300ms ease-out`,
    borderRadius: 0,
    fontSize: 17,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 17px',
    marginTop: 10,
    ':first-of-type': {
      marginTop: 5,
    },
    textAlign: 'center',
  },
  ({css, visible, type}) => ({
    opacity: visible ? 1 : 0,
    height: visible ? 60 : 0,
    ...(visible ? {} : {minHeight: 0, padding: 0}),
    ...NOTIFICATION_COLOR_SCHEME[type],
    ...css,
  }),
);

export const Wrapper = styled(Flex)(({top}) => ({
  width: '100%',
  position: 'fixed',
  left: 0,
  top,
  zIndex: MODAL_ZINDEX + 1,
}));
