import {gql} from '@apollo/client';

import {user} from '../../partials';

export default gql`
  mutation completeRegistration($token: String!) {
    completeRegistration(token: $token) {
      user {
        ${user}
      }
      jwt
      refreshToken
    }
  }
`;
