import {gql} from '@apollo/client';

// FYI, acquisition
export const AUTHENTICATE_BY_TOKEN = gql`
  mutation authenticateByToken($token: String!) {
    authenticateByToken(token: $token) {
      lead {
        id
      }
      jwt
    }
  }
`;
