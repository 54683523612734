import React from 'react';

import Message from '../../Message';

const BackupMessages = ({dark, left, shadow, bgColor, animate, botIcon}) => {
  return (
    <>
      <Message
        left={left}
        shadow={shadow}
        dark={dark}
        animate={animate}
        delayIndex={1}
        bgColor={bgColor}
        botIcon={botIcon}>
        Hi 👋, I’m the RenoFi Assistant Bot.
      </Message>
      <Message
        left={left}
        bgColor={bgColor}
        shadow={shadow}
        dark={dark}
        animate={animate}
        delayIndex={2}
        botIcon={botIcon}>
        How can I help you today?
      </Message>
    </>
  );
};

export default BackupMessages;
