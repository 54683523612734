import styled from '@emotion/styled/macro';
import {Tooltip} from 'react-tooltip';
import {Button, Text as RebassText, Link as RebassLink} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {blue, darkBlue} from '@renofi/utils/src/colors';

import infoIcon from '../Icons/info-solid.svg';

import closeIcon from './close-icon.svg';

export const TooltipWrapper = styled.div(
  mediaQuery({
    position: 'absolute',
    right: 0,
    padding: 'inherit',
    top: 3,
    '.react-tooltip-arrow': {
      background: 'white',
    },
  }),

  ({css}) => css,
);

export const TooltipButton = styled(Button)(
  {
    borderRadius: 100,
    padding: 0,
    outline: 'none',
  },
  ({clicked, iconWidth}) => ({
    background: `url(${clicked ? closeIcon : infoIcon})`,
    backgroundSize: '100% 100%',
    width: clicked ? 24 : iconWidth || 20,
    height: clicked ? 24 : iconWidth || 20,
    marginTop: clicked ? -2 : 0,
    marginRight: clicked ? -2 : 0,
  }),
);

export const StyledTooltip = styled(Tooltip)(({width}) =>
  mediaQuery({
    '&.react-tooltip__dark.react-tooltip__place-bottom': {
      '.react-tooltip-arrow': {
        background: darkBlue,
      },
    },
    '&.react-tooltip': {
      zIndex: 1000,
      background: 'none',
      padding: 0,
      maxWidth: width || [256, 340],
      width: 340,
      '&.show': {
        opacity: 1,
      },
    },
  }),
);

export const Text = styled(RebassText)({
  lineHeight: '20px',
  fontSize: 14,
});

export const Link = styled(RebassLink)({
  lineHeight: '10px',
  fontSize: 14,
  color: blue,
  '&:hover': {
    textDecoration: 'none',
  },
});

export const Strong = styled(RebassText)({
  fontSize: 14,
  fontWeight: 'bold',
});
