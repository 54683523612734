import {gql} from '@apollo/client';

export const SUBMIT_TO_WAIT_LIST = gql`
  mutation submitToWaitList(
    $afterRenovatedValue: Int
    $creditScore: String
    $email: String!
    $estimatedDownpayment: Int
    $estimatedProjectSize: String
    $firstMortgageBalance: Int
    $firstMortgageRate: Float
    $firstName: String!
    $homeValue: Int
    $loanSubject: LoanSubjectEnum
    $lastName: String!
    $loanAmount: Int
    $renovationCost: Int
    $stageOfPurchase: String
  ) {
    submitToWaitList(
      afterRenovatedValue: $afterRenovatedValue
      creditScore: $creditScore
      email: $email
      estimatedDownpayment: $estimatedDownpayment
      estimatedProjectSize: $estimatedProjectSize
      firstMortgageBalance: $firstMortgageBalance
      firstMortgageRate: $firstMortgageRate
      firstName: $firstName
      homeValue: $homeValue
      loanSubject: $loanSubject
      lastName: $lastName
      loanAmount: $loanAmount
      renovationCost: $renovationCost
      stageOfPurchase: $stageOfPurchase
    ) {
      success
    }
  }
`;
