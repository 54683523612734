import styled from '@emotion/styled';

import {Content, ModalContainer} from '@renofi/components/src/Modal/styled';

import arrow from './arrow.svg';

export const Back = styled.div({
  display: 'inline-block',
  width: 24,
  height: 24,
  backgroundImage: `url('${arrow}')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '24px 24px',
  backgroundPosition: 'left center',
  cursor: 'pointer',
  marginRight: 16,
});

export const modalCustomStyles = {
  height: '100%',
  overflow: ['auto', 'visible'],
  [Content]: {
    padding: 0,
    marginTop: '70px',
  },
  [ModalContainer]: {
    maxHeight: '100%',
    width: ['100%', 'auto'],
  },
};
