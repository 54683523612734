import styled from '@emotion/styled';
import {Switch as RebassSwitch} from '@rebass/forms';

import {green, lightGray} from '@renofi/utils';

export const Switch = styled(RebassSwitch)({
  background: green,
  border: 'none',
  width: 37,
  height: 20,
  cursor: 'pointer',
  '> div': {
    background: '#fff',
    border: 'none',
    width: 13,
    height: 13,
    marginLeft: '4px',
  },
  ':focus': {
    boxShadow: 'none',
  },
  '&[aria-checked=false]': {
    background: lightGray,
  },
  '&[disabled]': {
    background: lightGray,
    cursor: 'not-allowed',
  },
});
