import {gql} from '@apollo/client';

export const UPDATE_QUESTIONNAIRE = gql`
  mutation updateQuestionnaire(
    $attributes: QuestionnaireInputObject!
    $questionnaireId: ID
    $submittedStep: Int
    $token: String
  ) {
    updateQuestionnaire(
      attributes: $attributes
      questionnaireId: $questionnaireId
      submittedStep: $submittedStep
      token: $token
    ) {
      questionnaire {
        # contact
        contactFirstName
        contactLastName
        contactTitle
        email
        phoneNumber

        # company
        businessName
        businessType
        businessTypeOtherDetails
        website
        officePhoneNumber
        subsidaries
        streetAddress
        secondaryAddress
        city
        state
        zipCode
        teamSize
        hasW2Employees
      }
    }
  }
`;
