import styled from '@emotion/styled/macro';

import {mediaQuery} from '@renofi/utils';
import {gray} from '@renofi/utils/src/colors';

import {Table as StyledTable, Td as StyledTd} from '../Table';

export const Table = styled(StyledTable)(
  mediaQuery({
    fontSize: 14,
    color: gray,
    minWidth: 1000,
    background: 'white',
    marginTop: -1,
  }),
);

export const Td = styled(StyledTd)(
  mediaQuery({
    fontWeight: 300,
  }),
);
