import {gql} from '@apollo/client';

export default gql`
  query projectById($id: ID!) {
    projectById(id: $id) {
      id
      borrowersAndPropertyInformation {
        id
        firstName
        lastName
      }
      tasks {
        id
        taskType
        contractorInformations {
          id
          businessName
          estimatedCostOfWork
        }
      }
    }
  }
`;
