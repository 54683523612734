import {gql} from '@apollo/client';

export default gql`
  query signedUrl($objectName: StorageObjectName!) {
    signedUrl(objectName: $objectName) {
      objectName
      url
    }
  }
`;
