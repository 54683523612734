import styled from '@emotion/styled/macro';
import {
  Tab as UnstyledTab,
  TabList as UnstyledTabList,
  Tabs as UnstyledTabs,
  TabPanel as UnstyledTabPanel,
} from 'react-tabs';

import {red, lightGray, darkBlue} from '@renofi/utils/src/colors';
import {mediaQuery} from '@renofi/utils';

const Tabs = styled(UnstyledTabs)(
  mediaQuery({
    width: '100%',
  }),
);

const TabList = styled(UnstyledTabList)(
  mediaQuery({
    display: 'flex',
    justifyContent: ['space-between', 'flex-start'],
    padding: 0,
    margin: 0,
    borderBottom: `1px solid ${lightGray}`,
  }),
);

const Tab = styled(UnstyledTab)(
  mediaQuery({
    flexGrow: [1, 0],
    textAlign: 'center',
    padding: ['8px 0', '8px 18px'],
    marginTop: 10,
    marginBottom: -1,
    listStyle: 'none',
    cursor: 'pointer',
    '&:first-of-type': {
      borderLeft: 'none',
    },
    '&.react-tabs__tab--disabled': {
      color: '#e0e0e0',
      cursor: 'notAllowed',
    },
  }),
  ({selected}) =>
    mediaQuery({
      fontSize: [16, 20],
      fontWeight: 'bold',
      color: selected ? red : darkBlue,
      borderBottom: selected ? `1px solid ${red}` : 'none',
    }),
);

const TabPanel = styled(UnstyledTabPanel)(
  mediaQuery({
    minHeight: [265, 205],
    padding: '30px 0 0 0',
    textAlign: 'left',
  }),
  ({selected}) => ({
    display: selected ? 'block' : 'none',
  }),
);

Tab.tabsRole = 'Tab';
Tabs.tabsRole = 'Tabs';
TabPanel.tabsRole = 'TabPanel';
TabList.tabsRole = 'TabList';

export {Tab, TabList, Tabs, TabPanel};
