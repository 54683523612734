/* eslint-disable max-lines */
import {pick, isEmpty, isNull, isUndefined, omit} from 'lodash';
import {v4 as uuid} from 'uuid';

import creditEnum from '@renofi/api/src/enums';

import {
  PREQUAL_CONDITION_TYPE,
  PREQUAL_FAILURE_TYPE,
  MATCH_FAILURE_TYPE,
} from './const';
import {getDomain, getItem, setItem} from './cookies';

export const CONTRACTOR_CASE_CREATED_KEY = 'renofi_contractor_case_created';
export const FAKE_LEAD_DETAILS = {
  firstName: 'RenoFi',
  lastName: 'Experiment',
};

export const isFakeLead = ({firstName, lastName, email}) => {
  return (
    firstName === FAKE_LEAD_DETAILS.firstName ||
    lastName === FAKE_LEAD_DETAILS.lastName ||
    email?.includes('@renofiexperiment.com')
  );
};

export const getFakeDetails = () => ({
  ...FAKE_LEAD_DETAILS,
  email: `${uuid()}_${new Date().getTime()}@renofiexperiment.com`,
});

export const isLeadValidForCreation = (lead, omitted = []) => {
  const criteria = omit(
    {
      selfReportedCreditScore: true,
      email: true,
      firstName: true,
      lastName: true,
      loanSubject: true,
      zipCode: true,
    },
    omitted,
  );

  const isValid = (item) => {
    return !isNull(lead[item]) && !isUndefined(lead[item]);
  };

  return Object.keys(criteria).every(isValid);
};

export const getWarningReasons = ({
  prequalConditions = [],
  prequalFailures = [],
  loanProductMatchErrors,
}) => {
  const matchErrorReason = loanProductMatchErrors?.find((err) =>
    Object.values(MATCH_FAILURE_TYPE).includes(err),
  );
  return [...prequalConditions, ...prequalFailures, matchErrorReason].filter(
    Boolean,
  );
};

export const isLeadQualified = ({
  isLowCreditScore,
  stars,
  maxLoanAmount,
  haveMatches,
  inLicensedState,
  loanAmountDesiredForRenovation,
}) =>
  !isLowCreditScore &&
  stars !== 1 &&
  maxLoanAmount > 10000 &&
  haveMatches &&
  (inLicensedState || loanAmountDesiredForRenovation <= 150000);

export const getLeadCreditScore = (lead) =>
  lead.realCreditScoreNumber ||
  parseCreditScore(lead.creditScore) ||
  parseCreditScore(lead.selfReportedCreditScore);

export const parseCreditScore = (score) => {
  if (!score) return 0;
  return score === 'one' ? 620 : parseInt(creditEnum.get(score).split('-')[1]);
};

export const getEligibilityWarning = (lead) => {
  const reasons = getWarningReasons(lead);

  if (isLeadQualified(lead)) return null;

  if (
    lead.isLowCreditScore ||
    reasons.includes(PREQUAL_CONDITION_TYPE.CREDIT_SCORE)
  ) {
    return ELIGIBILITY_WARNINGS.LOW_CREDIT_SCORE;
  }
  if (reasons.includes(PREQUAL_CONDITION_TYPE.RECENT_BANKRUPTCY)) {
    return ELIGIBILITY_WARNINGS.RECENT_BANKRUPTCY;
  }
  if (reasons.includes(PREQUAL_CONDITION_TYPE.ESTIMATED_DTI)) {
    return ELIGIBILITY_WARNINGS.ESTIMATED_DTI;
  }
  if (reasons.includes(PREQUAL_CONDITION_TYPE.HAS_LATE_MORTGAGE_PAYMENTS)) {
    return ELIGIBILITY_WARNINGS.HAS_LATE_MORTGAGE_PAYMENTS;
  }
  if (reasons.includes(PREQUAL_FAILURE_TYPE.SMALL_LOAN)) {
    return ELIGIBILITY_WARNINGS.SMALL_LOAN;
  }
  if (reasons.includes(PREQUAL_FAILURE_TYPE.STATE) || !lead.inLicensedState) {
    return ELIGIBILITY_WARNINGS.STATE;
  }
  if (reasons.includes(PREQUAL_FAILURE_TYPE.USE_TYPE)) {
    return ELIGIBILITY_WARNINGS.USE_TYPE;
  }
  if (reasons.includes(PREQUAL_FAILURE_TYPE.STRUCTURE_TYPE)) {
    return ELIGIBILITY_WARNINGS.STRUCTURE_TYPE;
  }
  if (reasons.includes(PREQUAL_FAILURE_TYPE.PERFORMER)) {
    return ELIGIBILITY_WARNINGS.PERFORMER;
  }
  if (reasons.includes(MATCH_FAILURE_TYPE.DTI)) {
    return ELIGIBILITY_WARNINGS.DTI;
  }
  if (reasons.includes(MATCH_FAILURE_TYPE.PCLTV)) {
    return ELIGIBILITY_WARNINGS.PCLTV;
  }
  if (reasons.includes(MATCH_FAILURE_TYPE.ACLTV)) {
    return ELIGIBILITY_WARNINGS.ACLTV;
  }
  // default message if no other one applies
  if (isEmpty(reasons)) {
    return ELIGIBILITY_WARNINGS.UNQUALIFIED;
  }
};

export const ELIGIBILITY_WARNINGS = {
  LOW_CREDIT_SCORE: {
    id: 'low_credit_score',
    description: 'not qualified and low credit score',
  },
  ESTIMATED_DTI: {
    id: 'estimated_dti',
    description: 'estimated DTI may not be approved',
  },
  HAS_LATE_MORTGAGE_PAYMENTS: {
    id: 'has_late_mortgage_payments',
    description: 'having late mortgage payments may not be approved',
  },
  RECENT_BANKRUPTCY: {
    id: 'recent_bankruptcy',
    description: 'recent bankruptcy may not be approved',
  },
  SMALL_AMOUNT: {
    id: 'small_amount',
    description: 'not qualified and requested loan amount is too small',
  },
  STATE: {
    id: 'state',
    description: 'not qualified and unsupported state',
  },
  USE_TYPE: {
    id: 'use_type',
    description: 'not qualified and unsupported use type',
  },
  STRUCTURE_TYPE: {
    id: 'structure_type',
    description: 'not qualified and unsupported structure type',
  },
  PERFORMER: {
    id: 'performer',
    description:
      'not qualified and performer - doing renovation by themeselves',
  },
  DTI: {
    id: 'dti',
    description: 'not qualified and DTI is too high',
  },
  PCLTV: {
    id: 'pcltv',
    description: 'not qualified and PEAK CLTV is too high',
  },
  ACLTV: {
    id: 'acltv',
    description: 'not qualified and ARV CLTV is too high',
  },
  UNQUALIFIED: {
    id: 'unqualified',
    description: 'not qualified',
  },
};

export const LEAD_SESSION_DAYS = 30;
export const DASHBOARD_REDIRECT_READY_KEY = 'renofi_dashboard_redirect_ready';

export const getQueryParamPreFills = () => {
  const prefills = {};

  const urlParams = new URLSearchParams(window.location.search);
  const loanSubjectParam = urlParams.get('loanSubject');
  const loanAmountParam = urlParams.get('loanAmount');

  if (loanSubjectParam) {
    prefills['loanSubject'] = loanSubjectParam;
  }

  if (loanAmountParam) {
    prefills['loanAmount'] = Number(loanAmountParam);
    prefills['loanAmountDesiredForRenovation'] = Number(loanAmountParam);
  }

  return prefills;
};

const LEAD_KEY = 'renofi_lead';
const JWT_KEY = 'jwt';

export const getKeyWithUUID = (prefix, leadId) => {
  return leadId ? `${prefix}_${leadId}` : prefix;
};

export const isAuthenticated = (id) => {
  return Boolean(id && getLeadJwt(id));
};

export const saveLeadJwt = (jwt, leadId) => {
  const key = getKeyWithUUID(JWT_KEY, leadId);

  setItem({
    key,
    value: jwt,
    numberOfDays: LEAD_SESSION_DAYS,
    domain: getDomain(),
  });

  setItem({
    key: DASHBOARD_REDIRECT_READY_KEY,
    value: true,
    numberOfDays: LEAD_SESSION_DAYS,
    domain: getDomain(),
  });
};

export const getLeadJwt = (leadId) => {
  const key = getKeyWithUUID(JWT_KEY, leadId);
  const value = getItem(key);

  // For some time we need to support old depreciated keys without UUID
  if (isEmpty(value)) {
    return getItem(JWT_KEY);
  }
  return value;
};

export const savePersonalLeadData = (data, leadId) => {
  const existingData = getPersonalLeadData(leadId);
  const key = getKeyWithUUID(LEAD_KEY, leadId);

  setItem({
    key,
    value: JSON.stringify(
      pick({...existingData, ...data}, [
        'firstName',
        'lastName',
        'email',
        'phone',
        'designProjectDetails',
      ]),
    ),
    domain: getDomain(),
    numberOfDays: LEAD_SESSION_DAYS,
  });
};

export const getPersonalLeadData = (leadId) => {
  const key = getKeyWithUUID(LEAD_KEY, leadId);
  const value = JSON.parse(getItem(key) || '{}');

  // For some time we need to support old depreciated keys without UUID
  if (isEmpty(value)) {
    return JSON.parse(getItem(LEAD_KEY) || '{}');
  }

  return value;
};

export const isLendingTreeLead = (lead) =>
  Boolean(lead.leadSourceCrmName === 'Lending Tree');

export const isUncompletedLendingTreeLead = (lead) =>
  Boolean(
    isLendingTreeLead(lead) &&
      (!lead.renovationCost ||
        !lead.afterRenovatedValue ||
        !lead.annualIncomePretax),
  );

export const isIntroducedByContractor = (lead) =>
  Boolean(lead.leadSourceCrmName === 'Contractor intro');

export const isContractorIntroLeadIncomplete = (lead) =>
  Boolean(
    isIntroducedByContractor(lead) &&
      !(
        lead.loanAmountDesiredForRenovation &&
        lead.afterRenovatedValue &&
        lead.annualIncomePretax &&
        lead.selfReportedCreditScore
      ),
  );

export const allowNavbarCallSchedule = (lead) =>
  isLeadQualified(lead) || isContractorIntroLeadIncomplete(lead);

export const hasCRMOwner = (crmOwner) =>
  Boolean(
    crmOwner?.firstName && crmOwner?.lastName && crmOwner?.lastName !== 'API',
  );
