export default async ({id, messages, onChange, onSuccess}) => {
  let response;
  const clientId = process.env.REACT_APP_CF_ACCESS_CLIENT_ID;
  const secret = process.env.REACT_APP_CF_ACCESS_CLIENT_SECRET;

  try {
    response = await fetch(`${process.env.REACT_APP_RENO_CHAT_API_URL}/${id}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(clientId
          ? {'CF-Access-Client-Id': process.env.REACT_APP_CF_ACCESS_CLIENT_ID}
          : {}),
        ...(secret
          ? {
              'CF-Access-Client-Secret':
                process.env.REACT_APP_CF_ACCESS_CLIENT_SECRET,
            }
          : {}),
      },
      body: JSON.stringify({messages}),
    });
  } catch (error) {
    // Do catch
  }

  if (!response || !response.body) return {};

  let done, value;
  const reader = response?.body?.getReader();
  const chunks = [];
  const decoder = new TextDecoder();

  while (!done) {
    ({value, done} = (await reader?.read()) || {});
    if (done) {
      onSuccess && onSuccess(chunks);
      return chunks;
    }
    const string = decoder?.decode(value, {stream: true});
    chunks.push(string);
    onChange && onChange(string);
  }
};

export async function fetchHistory({id}) {
  let response;
  const title = document.title;
  const description = document.querySelector(
    'meta[name="description"]',
  ).content;

  try {
    response = await fetch(
      `${process.env.REACT_APP_RENO_CHAT_API_URL}/${id}?${new URLSearchParams({
        title,
        description,
      })}`,
      {
        method: 'GET',
        headers: {
          'CF-Access-Client-Id': process.env.REACT_APP_CF_ACCESS_CLIENT_ID,
          'CF-Access-Client-Secret':
            process.env.REACT_APP_CF_ACCESS_CLIENT_SECRET,
        },
      },
    );
  } catch (error) {
    // Do catch
  }

  return response?.json();
}
