import styled from '@emotion/styled';

import {lightGray, blue} from '@renofi/utils/src/colors';

export const Page = styled.button(
  {
    display: 'inline-block',
    fontSize: 14,
    lineHeight: '17px',
    textAlign: 'center',
    border: 'none',
    background: 'transparent',
    outline: 'none',
    padding: 12,
  },
  ({active, disabled}) => ({
    color: disabled ? lightGray : blue,
    cursor: disabled ? 'default' : 'pointer',
    fontWeight: active ? 'bold' : 'normal',
    borderBottom: `solid 2px ${active ? blue : 'transparent'}`,
    ...(disabled
      ? {}
      : {
          color: blue,
        }),
  }),
);
