export default `
  createdAt
  createdById
  designStatus
  email
  firstName
  hasInterestInFinancing
  homeownerType
  id
  lastName
  phoneNumber
  projectDetails
  updatedAt
`;
