export default `
coborrowerFirstName
coborrowerIsPresent
coborrowerLastName
firstName
lastName
borrowerEmail
propertyCity
propertyCounty
propertyOwnedByTrust
propertyState
propertyStreetAddressOne
propertyStreetAddressTwo
propertyStructureType
propertyType
propertyUseType
propertyZipCode
`;
