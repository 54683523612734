import React from 'react';

import styled from '@emotion/styled/macro';
import {keyframes} from '@emotion/react';

const spin = keyframes`
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
`;

const backSpin = keyframes`
    from {transform:rotate(360deg);}
    to {transform:rotate(0deg);}
`;

const SmallAnimation = styled.path`
  transform-origin: 3.7px 3.7px;
  animation: ${spin} 1.2s infinite linear;
`;

const LargeAnimation = styled.path`
  transform-origin: 5px 5px;
  animation: ${backSpin} 3.5s infinite linear;
`;

const LoaderIcon = () => {
  return (
    <svg
      style={{marginTop: 5}}
      width="43"
      height="40"
      viewBox="0 0 43 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="20"
        cy="20"
        r="19.4737"
        fill="#212F49"
        stroke="#212F49"
        strokeWidth="1.05263"
      />
      <path
        d="M36 27.5C31.9189 27.5 31.5 27 31.5 27"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M32.9473 14.2105C32.9473 13.6582 33.395 13.2105 33.9473 13.2105C34.4996 13.2105 34.9473 13.6582 34.9473 14.2105L32.9473 14.2105ZM32.9473 18.4211L32.9473 14.2105L34.9473 14.2105L34.9473 18.4211L32.9473 18.4211Z"
        fill="white"
      />
      <path d="M43 24L38.5 12.5L37 24H43Z" fill="#212F49" />

      <svg width="43" height="40" x="8" y="8">
        <SmallAnimation
          d="M2.75656 7.1599L2.54177 6.08592C2.39857 6.02625 2.26432 5.9636 2.13902 5.89797C2.01372 5.83234 1.88544 5.75179 1.75418 5.65632L0.71599 5.97852L0 4.76134L0.823389 4.04535C0.799523 3.89021 0.787589 3.73508 0.787589 3.57995C0.787589 3.42482 0.799523 3.26969 0.823389 3.11456L0 2.39857L0.71599 1.18138L1.75418 1.50358C1.88544 1.40811 2.01372 1.32757 2.13902 1.26193C2.26432 1.1963 2.39857 1.13365 2.54177 1.07399L2.75656 0H4.18854L4.40334 1.07399C4.54654 1.13365 4.68079 1.1963 4.80609 1.26193C4.93138 1.32757 5.05967 1.40811 5.19093 1.50358L6.22912 1.18138L6.94511 2.39857L6.12172 3.11456C6.14558 3.26969 6.15752 3.42482 6.15752 3.57995C6.15752 3.73508 6.14558 3.89021 6.12172 4.04535L6.94511 4.76134L6.22912 5.97852L5.19093 5.65632C5.05967 5.75179 4.93138 5.83234 4.80609 5.89797C4.68079 5.9636 4.54654 6.02625 4.40334 6.08592L4.18854 7.1599H2.75656ZM3.47255 5.01193C3.86635 5.01193 4.20346 4.87172 4.48389 4.59129C4.76432 4.31086 4.90453 3.97375 4.90453 3.57995C4.90453 3.18616 4.76432 2.84905 4.48389 2.56862C4.20346 2.28819 3.86635 2.14797 3.47255 2.14797C3.07876 2.14797 2.74165 2.28819 2.46122 2.56862C2.18079 2.84905 2.04057 3.18616 2.04057 3.57995C2.04057 3.97375 2.18079 4.31086 2.46122 4.59129C2.74165 4.87172 3.07876 5.01193 3.47255 5.01193Z"
          fill="white"
        />
      </svg>

      <svg width="43" height="40" x="13" y="15">
        <LargeAnimation
          d="M3.83055 10.0239L3.50835 8.52029C3.30549 8.44869 3.11754 8.36217 2.94451 8.26074C2.77148 8.15931 2.60143 8.04296 2.43437 7.9117L1.00239 8.37709L0 6.65871L1.14558 5.65632C1.12172 5.44153 1.10979 5.22673 1.10979 5.01193C1.10979 4.79714 1.12172 4.58234 1.14558 4.36754L0 3.36515L1.00239 1.64678L2.43437 2.11217C2.60143 1.98091 2.77148 1.86456 2.94451 1.76313C3.11754 1.66169 3.30549 1.57518 3.50835 1.50358L3.83055 0H5.83532L6.15752 1.50358C6.36038 1.57518 6.54833 1.66169 6.72136 1.76313C6.89439 1.86456 7.06444 1.98091 7.2315 2.11217L8.66348 1.64678L9.66587 3.36515L8.52029 4.36754C8.54415 4.58234 8.55609 4.79714 8.55609 5.01193C8.55609 5.22673 8.54415 5.44153 8.52029 5.65632L9.66587 6.65871L8.66348 8.37709L7.2315 7.9117C7.06444 8.04296 6.89439 8.15931 6.72136 8.26074C6.54833 8.36217 6.36038 8.44869 6.15752 8.52029L5.83532 10.0239H3.83055ZM4.83293 7.1599C5.42959 7.1599 5.93675 6.95107 6.35442 6.53341C6.77208 6.11575 6.98091 5.60859 6.98091 5.01193C6.98091 4.41527 6.77208 3.90811 6.35442 3.49045C5.93675 3.07279 5.42959 2.86396 4.83293 2.86396C4.23628 2.86396 3.72912 3.07279 3.31146 3.49045C2.89379 3.90811 2.68496 4.41527 2.68496 5.01193C2.68496 5.60859 2.89379 6.11575 3.31146 6.53341C3.72912 6.95107 4.23628 7.1599 4.83293 7.1599Z"
          fill="white"
        />
      </svg>
    </svg>
  );
};

export default LoaderIcon;
