import {gql} from '@apollo/client';

export const UPDATE_CONTRACTOR_LICENSE = gql`
  mutation updateContractorLicense(
    $expiryDate: Date
    $hasLicense: Boolean
    $noLicenseReasons: [NoLicenseReasonEnum!]
    $noLicenseReasonsOther: String
    $number: String
    $questionnaireId: ID
    $state: String
    $token: String
  ) {
    updateContractorLicense(
      expiryDate: $expiryDate
      hasLicense: $hasLicense
      noLicenseReasons: $noLicenseReasons
      noLicenseReasonsOther: $noLicenseReasonsOther
      number: $number
      questionnaireId: $questionnaireId
      state: $state
      token: $token
    ) {
      questionnaire {
        id
      }
    }
  }
`;
