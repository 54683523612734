/* eslint-disable max-lines */
import {toCurrency} from './format';

export const HUNDRED_YEARS_IN_DAYS = 100 * 365;

export const errorMessages = {
  minLoanAmount:
    'Lenders offering RenoFi Loans typically require a minimum loan amount of $1,000',
  maxLoanAmount: (maxLoanAmount) =>
    `Your estimated max loan amount is $${toCurrency(maxLoanAmount)}`,
};

export const FEEDBACK_PROVIDED_COOKIE_KEY = 'feedbackProvided';

export const UPDATE_PROJECT_DETAILS_KEY = 'renofi_updateProjectDetails';

export const UTM_COOKIE_KEYS = {
  renofi: 'renofi_utm_tags',
  pos: 'renofi_pos_utm_tags',
  calculator: 'renofi_calculator_utm_tags',
  slo: 'renofi_slo_utm_tags',
  cookieconsent: 'renofi_cookie_consent',
  contractors: 'renofi_contractors_utm_tags',
};

export const LEAD_ORIGIN_TYPE = {
  contractor: 'contractor',
  raasPartner: 'raas_partner',
  partnerSite: 'partner_site',
};

export const SELF_REPORTED_ORIGINS = {
  ONLINE_ADVERT: 'online_advert',
  CONTRACTOR: 'contractor',
  LOAN_OFFICER: 'loan_officer',
  REALTOR: 'realtor',
  CONSTRUCTION_PROFESSIONAL: 'construction_professional',
  ONLINE_SEARCH: 'online_search',
  RENOFI_PARTNER_WEBSITE: 'renofi_partner_website',
  FINANCIAL_INSTITUTION: 'financial_institution',
  RECOMMENDATION: 'recommendation',
  OTHER: 'other',
};

export const TURNDOWN_PARTNERS = {
  SPLASH: 'splash',
  VONTIVE: 'vontive',
  POINT: 'point',
  SOFI: 'sofi',
};

export const LOAN_SUBJECT = {
  CURRENT_HOME: 'renovate_current_home',
  HOME_PURCHASED: 'renovate_home_being_purchased',
  ADU: 'renovate_or_build_adu',
  HOME_BUILD: 'build_a_home_from_ground_up',
};

export const SESSION_FEATURE_FLAGS_STORAGE_KEY = 'sessionFeatureFlags';

export const RAAS_PARTNERS = {
  MID_PENN_BANK: {
    code: 'mid_penn_bank',
    name: 'Mid Penn Bank',
  },
  IDEAL_CREDIT_UNION: {
    code: 'ideal_credit_union',
    name: 'Ideal Credit Union',
  },
  FCCU: {
    code: 'fccu',
    name: 'First Community Credit Union',
  },
  PATELCO: {
    code: 'patelco',
    name: 'Patelco Credit Union',
  },
  COMMUNITY_CHOICE: {
    code: 'cccu',
    name: 'Community Choice Credit Union',
    maxArvCltv: 0.9,
  },
};

export function getRaasPartnerByCode(code) {
  const entries = Object.values(RAAS_PARTNERS);
  return entries.find((item) => item.code === code) || null;
}

export const RAAS_PARTNERS_CUSTOM_WIDTH = {
  [RAAS_PARTNERS.COMMUNITY_CHOICE.code]: [120, 170],
};

export const PREQUAL_CONDITION_TYPE = {
  CREDIT_SCORE: 'credit_score',
  ESTIMATED_DTI: 'estimated_dti',
  RECENT_BANKRUPTCY: 'recent_bankruptcy',
  HAS_LATE_MORTGAGE_PAYMENTS: 'has_late_mortgage_payments',
};

export const PREQUAL_FAILURE_TYPE = {
  STATE: 'state',
  USE_TYPE: 'property_use_type',
  STRUCTURE_TYPE: 'property_structure_type',
  PERFORMER: 'renovation_performed_by_type',
  SMALL_LOAN: 'loan_amount',
};

export const MATCH_FAILURE_TYPE = {
  DTI: 'max_loan_amount_by_dti',
  PCLTV: 'max_loan_amount_by_peak_cltv',
  ACLTV: 'max_loan_amount_by_arv_cltv',
};

export const SR_LOAN_OFFICERS = {
  447874: {
    firstName: 'Brian',
    lastName: 'Powell',
    avatar:
      'https://cdn.renofi.com/b4f46022-5f83-43d8-8b91-fcdec0efdd09/brianpowell.png',
  },
  2007343: {
    firstName: 'Cole',
    lastName: 'Fannin',
    avatar:
      'https://cdn.renofi.com/5a3e20a2-4bf6-49b8-b4fd-6a6a04605269/cole-fannin.png',
  },
  2176860: {
    firstName: 'Derron',
    lastName: 'Pocci',
    avatar:
      'https://cdn.renofi.com/dada0101-1533-432c-8b8a-cfc2ad998b3c/derron-pocci.png',
  },
  482343: {
    firstName: 'Frank',
    lastName: 'Gordon',
    avatar:
      'https://cdn.renofi.com/8c85d02d-9306-4212-ae1d-5e43be1d0ac8/frankgordon.png',
  },
  144593: {
    firstName: 'Lee',
    lastName: 'Blades',
    avatar:
      'https://cdn.renofi.com/bc46f48d-038e-421d-a903-6e807c135f28/leeblades.png',
  },
  334855: {
    firstName: 'Megan',
    lastName: 'Sfara',
    avatar:
      'https://cdn.renofi.com/f7c9645a-6c49-4b29-be88-923481ac6147/megansfara.jpg',
  },
  263954: {
    firstName: 'Skip',
    lastName: 'Schenker',
    avatar:
      'https://cdn.renofi.com/0bcc2a86-f5d5-4f94-b52b-94ce793fa386/skipschenker.png',
  },
  409816: {
    firstName: 'Todd',
    lastName: 'Bruess',
    avatar:
      'https://cdn.renofi.com/6a2eb84d-5999-4b48-b856-b8be7bb8c26e/todd-bruess.png',
  },
  379218: {
    firstName: 'Tom',
    lastName: 'Yoswa',
    avatar:
      'https://cdn.renofi.com/cd08fcd0-9883-4af8-a1a4-599ee5066ff2/tomyoswa.png',
  },
  891597: {
    firstName: 'Travis',
    lastName: 'Robinson',
    avatar:
      'https://cdn.renofi.com/33c1f040-dcd4-4423-9b05-df1373cbf73b/travisrobinson.png',
  },
  236174: {
    firstName: 'Wayne',
    lastName: 'Smith',
    avatar:
      'https://cdn.renofi.com/79af6b10-7eba-4b60-8d73-932cf812487d/wayne-smith.png',
  },
  461713: {
    firstName: 'Josh',
    lastName: 'Bartucca',
    avatar:
      'https://cdn.renofi.com/238190db-3198-4e14-8b62-cdacada5ea5c/joshbartucca.png',
  },
  287199: {
    firstName: 'Kevin',
    lastName: 'Patterson',
    avatar:
      'https://cdn.renofi.com/ae68b92b-9877-4151-8927-7ae2b003e3a4/kevinpatterson.png',
  },
  701671: {
    firstName: 'Joan',
    lastName: 'Gegenheimer',
    avatar:
      'https://cdn.renofi.com/bbc681b1-0af9-42b7-815a-06a4cf7624dc/joangegenhrimer.png',
  },
  1557281: {
    firstName: 'John',
    lastName: 'Holtz',
    avatar:
      'https://cdn.renofi.com/28d40f64-add5-4dc9-95ac-c5ceef23fbbe/johnholtz.png',
  },
  457656: {
    firstName: 'Tamara',
    lastName: 'Rodriguez',
    avatar:
      'https://cdn.renofi.com/77a40a4f-30e8-49f5-9f37-26c3ee107b01/tamararodriguez.png',
  },
  508642: {
    firstName: 'Christina',
    lastName: 'Bui',
    avatar:
      'https://cdn.renofi.com/f4f6fbb0-cb75-4279-a90a-6bd2f340d9ab/christinabui.png',
  },
  337043: {
    firstName: 'Brian',
    lastName: 'Michael',
    avatar:
      'https://cdn.renofi.com/6982767f-9e17-4eab-a638-0a624ff626e6/brianmichael.png',
  },
  1244997: {
    firstName: 'Abby',
    lastName: 'Schade',
    avatar:
      'https://cdn.renofi.com/9861d272-6a18-4b63-8a6b-104c8d4b0f5a/abbyschade.png',
  },
  1382550: {
    firstName: 'Kevin',
    lastName: 'Phan',
    avatar:
      'https://cdn.renofi.com/cf376a9e-657b-4cfc-9280-f9712ecac4a1/kevinphan.png',
  },
  273904: {
    firstName: 'Victor',
    lastName: 'Davis',
    avatar:
      'https://ucarecdn.com/80b7078a-7adb-49a1-a0bf-89c3cbfa6f50/victordavis.png',
  },
};

export const POINT_AVAILABLE_STATES = [
  'California',
  'Colorado',
  'Connecticut',
  'Georgia',
  'Illinois',
  'Indiana',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Missouri',
  'New Jersey',
  'New York',
  'North Carolina',
  'Ohio',
  'Oregon',
  'South Carolina',
  'Pennsylvania',
  'Virginia',
  'Washington',
  'Washington DC',
  'Wisconsin',
];

export const POINT_EXCLUDED_ZIP_CODES = [
  // Sacramento county CA
  '95823',
  '95630',
  '95624',
  '94509',
  '95758',
  '95608',
  '95808',
  '95828',
  '95670',
  // Riverside county CA
  '92501',
  '92502',
  '92503',
  '92504',
  '92505',
  '92506',
  '92507',
  '92508',
  '92513',
  '92514',
  '92516',
  '92517',
  '92521',
  '92522',
];

export const VONTIVE_EXCLUDED_STATES = [
  'Alaska',
  'Hawaii',
  'Nevada',
  'North Dakota',
  'South Dakota',
  'Wyoming',
];

export const SPLASH_EXCLUDED_STATES = ['Maryland', 'Vermont'];

export const SOFI_AVAILABLE_STATES = [
  'Alabama',
  'California',
  'Colorado',
  'Delaware',
  'DC',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Louisiana',
  'Maine',
  'Maryland',
  'Michigan',
  'Minnesota',
  'Missouri',
  'Montana',
  'Nevada',
  'North Dakota	',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island	',
  'South Carolina	',
  'South Dakota	',
  'Tennessee',
  'Texas',
  'Vermont',
  'Washington',
  'Wyoming',
];

export const SCHEDULE_CALENDAR_FEEDBACK_OPTIONS = [
  "I'd like to proceed without speaking to an Advisor",
  'I already scheduled a call',
  'I already got all the information I need',
  "The proposed times don't work for me",
  "I don't have designs and/or a cost estimate yet",
  'Other',
];
