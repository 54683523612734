import {gql} from '@apollo/client';

import {consolidatedEntity} from '../../partials';

export default gql`
  query consolidatedEntityById($id: ID, $entityId: ID, $entityType: String) {
    consolidatedEntityById(id: $id, entityId: $entityId, entityType: $entityType) {
      ${consolidatedEntity}
    }
  }
`;
