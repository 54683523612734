import styled from '@emotion/styled';
import {Flex} from 'rebass';

import {blue, darkBlue} from '@renofi/utils';

const borderColor = '#E2E2E2';
export const MessageWrapper = styled(Flex)(({left, dark, bgColor, shadow}) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 16px',
  borderRadius: 8,
  marginBottom: 16,
  marginLeft: left ? 52 : 0,
  textAlign: left ? 'left' : 'right',
  fontSize: 14,
  maxWidth: 298,
  border: `solid 1px ${dark ? darkBlue : '#FFFFFF78'}`,
  background: bgColor || (dark ? darkBlue : '#F5F6F7'),
  boxShadow: shadow ? '#00000026 0px 0px 8px 1px' : 'none',
  position: 'relative',
  a: {
    color: blue,
    textDecoration: 'underline',
    ':hover': {
      color: blue,
      textDecoration: 'none',
    },
  },
  p: {
    color: dark ? 'white' : 'black',
    lineHeight: '19px',
  },
  'ul, ol': {
    marginLeft: 24,
    marginBottom: 16,
    lineHeight: '21px',
  },
  li: {
    marginBottom: 8,
  },
  'p:not(:last-of-type)': {
    marginBottom: 16,
  },
  'p:last-of-type': {
    marginBottom: 0,
  },
  '.renochat-regular-link': {
    display: 'block',
    background: 'none',
    border: `solid 1px ${blue}`,
    textTransform: 'capitalize',
    borderRadius: 8,
    padding: '0px 16px',
    textDecoration: 'none',
    textAlign: 'center',
    margin: '10px 0',
    ':hover': {
      background: 'white',
      color: darkBlue,
    },
  },
  table: {
    marginBottom: 16,
    borderLeft: `solid 1px ${borderColor}`,
    borderTop: `solid 1px ${borderColor}`,
    borderCollapse: 'collapse',
    color: dark ? 'white' : '#202020',
    fontSize: 13,
    lineHeight: '18px',
    th: {
      background: '#EFEFEF',
    },
    'th, td': {
      padding: '3px 6px',
      textAlign: 'left',
      borderBottom: `solid 1px ${borderColor}`,
      borderRight: `solid 1px ${borderColor}`,
    },
  },
}));

export const IconWrapper = styled(Flex)(() => ({
  position: 'absolute',
  width: 40,
  height: 40,
  left: -56,
  bottom: 4,
}));
