import PropTypes from 'prop-types';

const Toggle = ({children, show}) => {
  if (!show) return null;
  return children;
};

Toggle.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default Toggle;
