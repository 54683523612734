import {gql} from '@apollo/client';

import {contractorEstimate as estimate} from '../../partials';

export default gql`
  mutation referClientForFinancing($client: ContractorClientInputObject!) {
    referClientForFinancing(client: $client) {
      contractorEstimate {
        ${estimate}
      }
    }
  }
`;
