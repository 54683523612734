import React from 'react';

import PropTypes from 'prop-types';
import {noop} from 'lodash';

import {states as STATES} from '@renofi/utils';

import SelectField from '../SelectField';

const STATE_KEYS = Object.keys(STATES);

const StatesField = ({
  disabledStates = [],
  disabledSuffix = '',
  id = 'state',
  name = 'state',
  onChange = noop,
  required = true,
  value,
  ...props
}) => {
  return (
    <SelectField
      autoComplete="false"
      value={value}
      onChange={onChange}
      id={id}
      name={name || id}
      required={required}
      {...props}>
      {value ? null : <option />}
      {STATE_KEYS.map((key) => {
        const disabled = disabledStates.includes(key);
        return (
          <option disabled={disabled} key={key} value={key}>
            {STATES[key]}
            {disabled && disabledSuffix ? disabledSuffix : null}
          </option>
        );
      })}
    </SelectField>
  );
};

StatesField.propTypes = {
  disabledStates: PropTypes.arrayOf(PropTypes.string),
  disabledSuffix: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
};

export default StatesField;
