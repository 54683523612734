import styled from '@emotion/styled';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';
export const Content = styled(Flex)(
  mediaQuery({
    justifyContent: 'center',
    alignItems: 'center',
    width: ['100%', 400],
    maxWidth: '100%',
    height: '100%',
    maxHeight: ['100%', 'calc(100% - 60px)'],
    marginBottom: 60,
    marginRight: -5,
    borderRadius: [0, 20],
    background: 'white',
    boxShadow: '0px 0px 15px 0px #00000024',
    overflow: 'hidden',
  }),
);

export const ChatMessages = styled(Flex)(
  mediaQuery({
    flexDirection: 'column',
    flex: 'auto',
    padding: '16px 24px 0px 24px',
    overflow: 'auto',
    maskImage:
      '-webkit-gradient(linear, left .3%, left top, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))',
  }),
);
