import {getDomain, setItem} from '../cookies';
import {isRenofiPreview} from '../environment';

const isSharedCookie = !isRenofiPreview();

export default function removeRefreshToken() {
  setItem({
    key: 'refreshToken',
    value: '',
    numberOfDays: -1,
    ...(isSharedCookie ? {domain: getDomain()} : {}),
  });
}
