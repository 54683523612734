import {gql} from '@apollo/client';

export const SUBMIT_TCPA_CONSENT = gql`
  mutation submitTcpaConsent(
    $scenarioId: ID!
    $phone: String!
    $consentMedium: String!
    $consentMessage: String!
  ) {
    submitTcpaConsent(
      scenarioId: $scenarioId
      phone: $phone
      consentMedium: $consentMedium
      consentMessage: $consentMessage
    ) {
      success
    }
  }
`;
