import React from 'react';

import ReactDOM from 'react-dom/client';

import './index.css';
import {logger} from '@renofi/utils';

import App from './App';

export function initRenoChat(id) {
  logger.log(`Initializing RenoChat into HTML element with selector: ${id}`);

  const root = ReactDOM.createRoot(document.getElementById(id));

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}

if (typeof window.RenoChat === 'undefined') {
  window.RenoChat = {
    initRenoChat,
  };
}

window.dispatchEvent(new Event('RenoChat/Loaded'));
