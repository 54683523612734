import {keyframes} from '@emotion/react';
import styled from '@emotion/styled/macro';

import {
  gray,
  darkBlue,
  fadeYellow,
  fadeRed,
  darkRed,
  darkYellow,
  fadeBlue,
} from '@renofi/utils/src/colors';
import {mediaQuery} from '@renofi/utils';

import sortIcon from './img/sort.svg';
import gradientIcon from './img/gradient-bg.png';
import arrowIcon from './img/arrow.svg';
import swipeIcon from './img/swipe.svg';

const tableBorder = '1px solid #D8D8D8';

const responsiveStyle = {
  maxWidth: '34vw',
  position: 'sticky',
  left: 0,
  zIndex: 1,
  transform: 'scale(1)',
  background: [`white url(${gradientIcon}) repeat-y`, 'none'],
  backgroundPositionX: 'right',
  backgroundSize: 7,
  paddingRight: 25,
};

export const scale = keyframes`
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1.5);
  }
`;

const swipeIndication = {
  display: ['block', 'none'],
  transform: 'scale(1.5)',
  zIndex: 2,
  animation: `${scale} 0.8s infinite`,
  content: `" "`,
  width: 110,
  height: 60,
  background: `url(${swipeIcon}) no-repeat`,
  position: 'absolute',
  left: 'calc(50vw - 55px)',
  top: 'calc(50% - 25px)',
  pointerEvents: 'none',
};

export const Table = styled.table(
  ({css, responsive, clickable, showSwipeIcon, horizontal, bold}) =>
    mediaQuery({
      width: '100%',
      color: darkBlue,
      alignItems: 'center',
      borderCollapse: 'collapse',
      borderStyle: 'hidden',
      borderTop: bold ? 'none' : tableBorder,
      borderBottom: [tableBorder, 'none'],
      lineHeight: '16px',
      ...css,
      'td, tr': {
        borderTop: tableBorder,
        ...(horizontal ? {} : {border: tableBorder}),
        ...(bold ? {border: 'none'} : {}),
        ...(bold ? {borderBottom: tableBorder} : {}),
      },
      'tr th:first-of-type, tr td:first-of-type': {
        ...(responsive ? responsiveStyle : {}),
      },
      tr: {
        cursor: clickable ? 'pointer' : 'default',
        '&:hover': {
          td: {
            backgroundColor: clickable ? fadeBlue : 'none',
          },
        },
      },
      position: 'relative',
      ':before': {
        ...(showSwipeIcon ? swipeIndication : {}),
      },
    }),
);

export const Td = styled.td(({danger, warning, css}) =>
  mediaQuery({
    padding: 16,
    fontWeight: 'normal',
    backgroundColor: (danger && fadeRed) || (warning && fadeYellow),
    color: (danger && darkRed) || (warning && darkYellow),
    ...css,
  }),
);

export const Th = styled.th(({css, onClick, sortOrder, isSorted}) =>
  mediaQuery({
    textAlign: 'left',
    fontWeight: 'normal',
    backgroundColor: '#F8F8F8',
    color: gray,
    height: 64,
    paddingLeft: 16,
    paddingRight: 16,
    position: 'sticky',
    top: 0,
    ...css,
    cursor: onClick ? 'pointer' : 'default',
    ':after': onClick
      ? {
          content: '" "',
          transformOrigin: '60% 60%',
          transform: `rotate(${isSorted && sortOrder ? 180 : 0}deg)`,
          backgroundImage: `url(${isSorted ? arrowIcon : sortIcon})`,
          opacity: isSorted ? 1 : 0.3,
          position: 'absolute',
          top: 'calc(50% - 8px)',
          right: [16, 10],
          width: 13,
          height: 15,
        }
      : {},
  }),
);
