import styled from '@emotion/styled/macro';
import {Flex, Box, Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const SecuritySealContainer = styled(Flex)(
  mediaQuery({
    position: 'relative',
    flexDirection: ['column', 'row'],
    alignItems: ['center', 'start'],
    justifyContent: 'center',
    padding: '16px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.198126)',
    borderRadius: '8px',
  }),
);

export const SecurityMessage = styled(Text)(
  mediaQuery({
    marginTop: ['4px', 0],
    fontSize: '14px',
    lineHeight: '17px',
    color: '#737373',
    margin: ['15px', '0 0 15px 15px'],
  }),
);

export const CloudFlareWrapper = styled(Box)({
  position: 'absolute',
  bottom: 10,
  right: 10,
});
