import styled from '@emotion/styled/macro';
import {Input as RebassInput} from '@rebass/forms';
import {Text} from 'rebass';

import {lightGreen, lightRed, lightGray} from '@renofi/utils/src/colors';
import {mediaQuery} from '@renofi/utils';

export const TextField = styled(RebassInput)(
  mediaQuery({
    fontWeight: 'bold',
    borderRadius: 10,
    fontSize: 40,
    width: ['18%', 67],
    maxWidth: 67,
    height: ['calc(100vw * 0.2)', 88],
    maxHeight: 88,
    margin: 5,
    padding: '5px 0 0 0',
    background: 'white',
    textAlign: 'center',
    ':focus': {
      outline: 'none',
    },
  }),
  ({empty, error}) => ({
    color: error ? lightGray : 'black',
    border: `solid 2px ${error ? lightRed : empty ? '#D4DBD5' : lightGreen}`,
  }),
);

export const Period = styled(Text)(
  mediaQuery({
    fontWeight: 'bold',
    fontSize: 60,
    marginBottom: 20,
    color: '#737373',
  }),
);
