import {gql} from '@apollo/client';

export const LINK_ANALYTICS_SUMMARIES = gql`
  query linkAnalyticsSummaries($shortCodes: [String!]!) {
    linkAnalyticsSummaries(shortCodes: $shortCodes) {
      id
      mostVisitedBy {
        clicksCount
        ipAddress
        lastAccessedAt
      }
      shortCode
      totalClicks
    }
  }
`;
