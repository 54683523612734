export default {
  onOpen: () => {
    window.RenoChat.isOpen = true;
    window.RenoChat.onOpen && window.RenoChat.onOpen();
  },
  onClose: () => {
    window.RenoChat.isOpen = false;
    window.RenoChat.onClose && window.RenoChat.onClose();
  },
  onUserTyping: () => {
    window.RenoChat.hasUserInteraction = true;
    window.RenoChat.onUserTyping && window.RenoChat.onUserTyping();
  },
};
