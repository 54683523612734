import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {Flex, Text, Card, Box} from 'rebass';
import {noop} from 'lodash';

import {gray, lightGray, lightBlue} from '@renofi/utils';

import Button from '../Button';
import DocumentViewer from '../DocumentViewer';
import FileInput from '../FileInput';
import Label from '../Label';
import Loader from '../Loader';
import Toggle from '../Toggle';

import {
  UploadedFile,
  FileInfo,
  Wrapper,
  ButtonWrapper,
  FileName,
  FileStatus,
  TrashButton,
} from './styled';
import {ReactComponent as TrashIcon} from './img/trash-icon.svg';
import {FileTypeIcon, formatBytes} from './utils';

const renofiProvided = (
  <Flex width={1} alignItems="center">
    <Text ml={16}>RenoFi uploads</Text>
  </Flex>
);

const UploadDocuments = ({
  accept,
  onAcceptFiles = noop,
  onRejectFiles = noop,
  label,
  uploadText,
  onFileRemove,
  onFileView,
  disabled,
  fileStatus,
  file,
  isUploadInProgress,
}) => {
  const isFileUploaded = Boolean(file?.objectName);
  const isFileRejected = Boolean(file?.rejectionReason);
  const [viewFile, setViewFile] = useState(false);
  const [document, setDocument] = useState({
    fileName: '',
    fileUrl: '',
  });
  const [loading, setLoading] = useState(false);
  const showDropZone =
    !isUploadInProgress && (!isFileUploaded || file?.rejectionReason);

  useEffect(() => {
    setDocument({
      ...document,
      fileName: getFileName(),
    });
  }, [file?.objectName]);

  async function onClick() {
    setLoading(true);
    setViewFile(true);
    const url = await onFileView(file?.objectName);
    setDocument({
      ...document,
      fileUrl: url,
    });
    setLoading(false);
  }

  function getFileName() {
    const name = file?.objectName?.split('/');
    return name?.pop();
  }

  return (
    <>
      {Boolean(label) ? (
        <Label textAlign="left" small htmlFor={label} mb={1}>
          {label}
        </Label>
      ) : null}

      <Card p={0} mb={[0, 24]} title={renofiProvided}>
        {isFileUploaded && (
          <UploadedFile isRejected={isFileRejected}>
            <div
              style={{
                height: '32px',
              }}>
              <FileTypeIcon
                isRejected={isFileRejected}
                fileName={file?.objectName}
              />
            </div>
            <FileInfo>
              <FileName>{document?.fileName}</FileName>
              <FileStatus isRejected={isFileRejected}>{fileStatus}</FileStatus>
            </FileInfo>

            <Toggle show={!isUploadInProgress}>
              <ButtonWrapper>
                <Button
                  small
                  bgColor="white"
                  color={gray}
                  borderColor={lightGray}
                  width={['100px', '100px']}
                  css={{
                    height: '30px',
                    padding: ['4px 8px', '5px 10px'],
                    fontSize: ['12px', '14px'],
                    marginTop: ['19px', 0],
                    marginLeft: ['-5px', 0],
                  }}
                  onClick={onClick}>
                  View File
                </Button>
              </ButtonWrapper>
            </Toggle>
            <Wrapper>
              <Text fontSize="12px" color={gray}>
                {formatBytes(file?.fileSizeBytes)}
              </Text>
              <Toggle show={!isFileRejected && !isUploadInProgress}>
                <TrashButton transparent onClick={onFileRemove}>
                  <TrashIcon />
                </TrashButton>
              </Toggle>
              <Toggle show={isUploadInProgress}>
                <Box ml={20}>
                  <Loader color={lightBlue} size={1} />
                </Box>
              </Toggle>
            </Wrapper>
          </UploadedFile>
        )}
        <Toggle show={showDropZone}>
          <FileInput
            accept={accept}
            disabled={disabled}
            text={uploadText}
            onAcceptFiles={onAcceptFiles}
            onRejectFiles={onRejectFiles}
          />
        </Toggle>
        <Toggle show={isUploadInProgress && !isFileUploaded}>
          <Loader mt={10} color={lightBlue} size={1.5} />
        </Toggle>
        {viewFile && !loading && (
          <DocumentViewer
            visible={viewFile}
            document={document}
            onClose={() => setViewFile(false)}
          />
        )}
      </Card>
    </>
  );
};

UploadDocuments.propTypes = {
  onAcceptFiles: PropTypes.func,
  onRejectFiles: PropTypes.func,
  onViewDocument: PropTypes.func,
  onClosePreview: PropTypes.func,
  uploadText: PropTypes.string,
  label: PropTypes.string,
  onFileRemove: PropTypes.func,
  disabled: PropTypes.bool,
  onFileView: PropTypes.func,
  fileStatus: PropTypes.string,
  isUploadInProgress: PropTypes.bool,
  file: PropTypes.shape({
    objectName: PropTypes.string,
    fileSizeBytes: PropTypes.number,
    source: PropTypes.string,
    createdAt: PropTypes.string,
    rejectionReason: PropTypes.string,
    rejectionDetails: PropTypes.string,
  }),
};

export default UploadDocuments;
