import {gql} from '@apollo/client';

import {daasClient} from '../../partials';

export default gql`
  query daasClients($contractorId: ID, $limit: Int, $page: Int, $status: [DaasLeadStatusEnum!]) {
    daasClients(contractorId: $contractorId, limit: $limit, page: $page, status: $status) {
      collection {
        ${daasClient}
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;
