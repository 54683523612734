import {gql} from '@apollo/client';

export const SUBMIT_QUESTIONNAIRE_REFERENCE = gql`
  mutation submitQuestionnaireReference(
    $attributes: ReferenceInputObject!
    $questionnaireId: ID
    $token: ID
  ) {
    submitQuestionnaireReference(
      attributes: $attributes
      questionnaireId: $questionnaireId
      token: $token
    ) {
      reference {
        id
      }
    }
  }
`;
