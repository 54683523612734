import React from 'react';

import remarkGfm from 'remark-gfm';
import PropTypes from 'prop-types';
import {useSpring, animated} from 'react-spring';
import Markdown from 'react-markdown';

import {jumpIn} from '@renofi/utils/src/animation';
import {Toggle} from '@renofi/components';

import FaceIcon from '../ToggleButton/FaceIcon';

import LoaderIcon from './LoaderIcon';
import {IconWrapper, MessageWrapper} from './Message.styled';
import MarkdownLink from './MarkdownLink';

const Message = ({
  id,
  children,
  loading,
  left,
  delayIndex = 0,
  animate,
  shadow,
  dark,
  botIcon = true,
  bgColor,
}) => {
  const spring = useSpring({
    from: {opacity: 0, transform: 'scale(0, 0)'},
    to: {opacity: 1, transform: 'scale(1, 1)'},
    config: jumpIn,
    delay: 100 * delayIndex,
  });

  if (!children) return null;

  const components = {
    a: ({...props}) => <MarkdownLink {...props} />,
  };

  return (
    <animated.div
      id={id}
      style={{
        ...(animate ? spring : {}),
        transformOrigin: 'bottom right',
        alignSelf: left ? 'flex-start' : 'flex-end',
      }}>
      <MessageWrapper bgColor={bgColor} dark={dark} shadow={shadow} left={left}>
        <Toggle show={left && botIcon}>
          <IconWrapper>
            {loading ? (
              <animated.div style={spring}>
                <LoaderIcon />
              </animated.div>
            ) : (
              <FaceIcon animate={false} />
            )}
          </IconWrapper>
        </Toggle>

        <Markdown remarkPlugins={[remarkGfm]} components={components}>
          {children}
        </Markdown>
      </MessageWrapper>
    </animated.div>
  );
};

Message.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  delayIndex: PropTypes.number,
  left: PropTypes.bool,
  loading: PropTypes.bool,
  shadow: PropTypes.bool,
  dark: PropTypes.bool,
  animate: PropTypes.bool,
  timestamp: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  botIcon: PropTypes.bool,
  bgColor: PropTypes.string,
};

export default Message;
