import {gql} from '@apollo/client';

import {daasClient} from '../../partials';

export default gql`
  query daasClientById($id: ID!) {
    daasClientById(id: $id) {
      ${daasClient}
    }
  }
`;
