import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const HeaderWrapper = styled(Flex)(
  mediaQuery({
    justifyContent: ['center', 'start'],
    alignItems: 'center',
    background: ['none', '#FFFFFF'],
    height: ['auto', 70],
    marginTop: [32, 0],
    marginBottom: [40, 0],
  }),
);
