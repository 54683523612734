import {gql} from '@apollo/client';

export default gql`
  mutation requestUserRegistration(
    $accountCategory: AccountCategory!
    $email: String!
    $acceptedAgreements: AgreementsInputObject
  ) {
    requestUserRegistration(
      accountCategory: $accountCategory
      email: $email
      acceptedAgreements: $acceptedAgreements
    ) {
      success
    }
  }
`;
