import {gql} from '@apollo/client';

import {user} from '../../partials';

export default gql`
  mutation acceptAgreements(
    $contractorTermsOfService: Boolean
    $lenderAccess: Boolean
    $electronicSignature: Boolean
    $privacyPolicyAndGlba: Boolean
    $termsOfService: Boolean
    $appraisalFeeConsent: Boolean
    $compensationByLenderConsent: Boolean
  ) {
    acceptAgreements(
      contractorTermsOfService: $contractorTermsOfService
      lenderAccess: $lenderAccess
      electronicSignature: $electronicSignature
      privacyPolicyAndGlba: $privacyPolicyAndGlba
      termsOfService: $termsOfService
      appraisalFeeConsent: $appraisalFeeConsent
      compensationByLenderConsent: $compensationByLenderConsent
    ) {
      user {
        ${user}
      }
    }
  }
`;
