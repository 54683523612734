import styled from '@emotion/styled/macro';
import {Box, Text, Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {darkBlue} from '@renofi/utils/src/colors';

export const Banner = styled(Box)(
  mediaQuery({
    padding: ['10px 23px 24px 23px', '24x 29px'],
    borderRadius: 8,
  }),
  ({css, dark}) =>
    mediaQuery({
      ...css,
      background: dark ? darkBlue : 'white',
      color: dark ? 'white' : darkBlue,
    }),
);

export const Heading = styled(Flex)(
  mediaQuery({
    gap: 10,
    alignItems: ['flex-start', 'center'],
    justifyContent: 'center',
  }),
);

export const HeadingText = styled(Text)(
  mediaQuery({
    fontWeight: 'bold',
    marginBottom: 4,
  }),
  ({dark}) =>
    mediaQuery({
      flexBasis: dark ? ['93%', '100%'] : ['100%', '100%'],
    }),
);

export const IconWrapper = styled.div(
  mediaQuery({
    height: 21,
    marginBottom: 4,
  }),
  ({dark}) =>
    mediaQuery({
      position: dark ? ['absolute', 'static'] : 'static',
      left: dark ? ['6%', 0] : 0,
    }),
);

export const BigText = styled(Text)(
  mediaQuery({
    fontSize: 24,
    lineHeight: '36px',
    display: 'inline',
  }),
);

export const Content = styled.div(
  mediaQuery({
    fontSize: 14,
    lineHeight: '21px',
  }),
  ({dark}) => ({
    marginLeft: dark ? 0 : 31,
  }),
);
