import {datadogRum} from '@datadog/browser-rum';
import {uniq} from 'lodash';

import {isRenofiPreview} from '@renofi/utils/src/environment';

import {IGNORE_ERRORS} from './constants';

const APP_ENV = process.env.REACT_APP_ENV;
const IS_DEV = process.env.NODE_ENV === 'development';
const applicationId = process.env.REACT_APP_DATA_DOG_APP_ID;
const clientToken = process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN;
const site = process.env.REACT_APP_DATA_DOG_SITE;
const service = process.env.REACT_APP_SERVICE_NAME;

let validEnvs = [];

export default function initDataDog({
  envs = ['production'],
  ignoreErrors = [],
} = {}) {
  validEnvs = uniq(validEnvs.concat(envs));
  if (IS_DEV || !envs.includes(APP_ENV) || isRenofiPreview()) return;

  datadogRum.init({
    applicationId,
    clientToken,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site,
    env: APP_ENV,
    service,
    version: process.env.REACT_APP_COMMIT_REF,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: [
      'localhost',
      /https:\/\/.*\.renofistaging\.com/,
      /https:\/\/.*\.renofi\.com/,
    ],
    defaultPrivacyLevel: 'mask-user-input',
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    beforeSend: (event) => {
      const isIgnored = [...IGNORE_ERRORS, ...ignoreErrors].some((value) => {
        return event?.error?.message.includes(value);
      });
      if (event.type === 'error' && isIgnored) return false;
      return true;
    },
  });
}

export const setDataDogUser = (user) => {
  if (!validEnvs.includes(APP_ENV) || !datadogRum) return;

  datadogRum.setUser(user);
};
