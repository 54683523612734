import {states} from '@renofi/utils/src/states';

export const productTypes = {
  RENOFI_HELOC: 'renofi_heloc',
  RENOFI_HOME_EQUITY: 'renofi_home_equity',
};

export const propertyUse = {
  primary_residence: 'Primary residence',
  investment_property: 'Investment property',
  secondary_residence: 'Second home or Vacation home',
};

export const teardown = {
  teardown: 'teardown_everything',
  partial_teardown: 'parts_of_structure_remain',
  no_structure: 'no_structures_on_land',
};

export const occupancyTypes = {
  investment_property: 'Investment property',
  not_occupied: 'Not occupied',
  other: 'Other',
  primary_residence: 'Primary residence',
  second_home: 'Second home',
};

export const propertyStructure = {
  single_family: 'Single family',
  townhouse: 'Townhouse',
  adu: 'Adding ADU',
  condo: 'Condo',
  fnma_warrantable_condo: 'Fnma Warrantable Condo',
  co_op: 'Co-op',
  multi_unit: 'Multi-unit',
  multi_unit_owner_occupied: 'Multi-unit owner occupied',
  mixed_use: 'Mixed use',
  row_home: 'Row home',
  land: 'Land only',
};

export const renovationPerformedBy = {
  alone: 'I’m doing everything myself',
  hiring: 'I’m hiring a licensed & insured general contractor',
  subcontracting:
    'I’m acting as the general contractor and hiring licensed & insured subcontractors',
};
export const renovationStart = {
  already_started: "I've already started",
  in_next_2_months: 'In next 2 months',
  in_3_6_months: 'In 3-6 months',
  in_6_12_months: 'In 6-12 months',
  more_than_one_year: "Not sure, I'm just researching",
};

export const purchaseClose = {
  thirty: 'In next 30 days',
  sixty: 'In next 60 days',
  more_than_sixty: 'In more than 60 days',
};

export const creditScore = {
  twelve: '780-800+',
  eleven: '760-779',
  ten: '740-759',
  nine: '720-739',
  eight: '700-719',
  seven: '690-699',
  six: '680-689',
  five: '670-679',
  four: '660-669',
  three: '640-659',
  two: '620-639',
  one: 'Below 620',
};

export const stageOfPurchase = {
  researching: 'researching',
  making_offers: 'making_offers',
  offer_accepted: 'offer_accepted',
  closed_not_moved_in: 'closed_not_moved_in',
};

export const land = {
  owned: 'owned',
  to_be_purchased: 'to_be_purchased',
};

export const employmentStatus = {
  employee: 'employee',
  self_employed: 'self_employed',
  other: 'other',
};

export const projectSize = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export const loanSubject = {
  renovate_current_home: 'My current home',
  renovate_home_being_purchased: 'A home I’m buying',
  build_a_home_from_ground_up: 'Building a home from the ground up',
  renovate_or_build_adu: 'Accessory Dwelling Unit (ADU)',
};

export const forEachLoanSubject = (callback) => {
  return forEach(loanSubject, callback);
};

export const forEachPurchaseStages = (callback) => {
  return forEach(stageOfPurchase, callback);
};

export const forEachCreditMap = (callback) => {
  return forEach(creditScore, callback);
};

export const forEachStateMap = (callback) => {
  return forEach(states, callback);
};

function forEach(enums, callback) {
  return Object.entries(enums).map(([key, value]) => callback(key, value));
}

export default {
  get: (key) => {
    if (!key) return null;

    const item = [
      productTypes,
      propertyUse,
      projectSize,
      propertyStructure,
      renovationPerformedBy,
      renovationStart,
      purchaseClose,
      creditScore,
      employmentStatus,
      stageOfPurchase,
      loanSubject,
      occupancyTypes,
    ].find((item) => item[key]);

    try {
      return item[key];
    } catch {
      throw new Error(
        `Unsupported enum (${key}) in use. Was new value added to BE recently?`,
      );
    }
  },
};
