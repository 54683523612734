import {gql} from '@apollo/client';

export default gql`
  query uploadMetadata(
    $fileName: String!
    $contentType: AllowedContentType!
    $basePath: AllowedStoragePath
    $recordId: String
  ) {
    uploadMetadata(
      fileName: $fileName
      contentType: $contentType
      basePath: $basePath
      recordId: $recordId
    ) {
      recordId
      expires
      fileName
      httpMethod
      url
      objectName
    }
  }
`;
