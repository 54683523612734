export default `
  id

  avgNumberOfRenovationsPerYear
  avgPriceOfProjects
  bankruptcyDetails
  businessName
  businessType
  businessTypeOtherDetails
  city
  contactFirstName
  contactLastName
  contactTitle
  createdAt
  email
  firstOpenedAt
  gcddReviews {
    id
    cancelledAt
    borrowerName
    estimatedCostOfWork
    propertyAddress
  }
  hasBeenInvolvedInLawsuit
  hasEverFiledForBankruptcy
  hasLicense
  hasTaxLiens
  hasW2Employees
  hasWorkersCompInsurance
  involvedInLawsuitDetails
  lastSentAt
  licenseNumber
  noInsuranceReasons
  noInsuranceReasonsOther
  noLicenseReasons
  noLicenseReasonsOther
  numberOfCompletedRenovations
  officePhoneNumber
  percentageLeadsNeedingExpertDesign
  percentageSalesSpentOnMarketing
  percentageBusinessWithResidentialInLast2y
  percentageBusinessWithCommercialInLast2y
  percentageCompletedRenovationsAmountRanges
  phoneNumber
  propertyState
  residentialHomeRemodelBlueprintingOption
  residentialHomeRemodelBlueprintingOptionOther
  residentialHomeRemodelRole
  residentialHomeRemodelRoleOther
  returnedAt
  secondaryAddress
  signatoryBusinessName
  signatoryName
  signatoryTitle
  signatoryType
  signatureDate
  state
  streetAddress
  submittedStep
  subsidaries
  taxLiensDetails
  teamSize
  typicalRenovationProjects
  typicalRenovationProjectsOtherDetails
  updatedAt
  website
  workersCompIncludedInGlid
  yearsAsAContractor
  zipCode

  contractorLicense {
    contentType
    contractorId
    createdAt
    documentType
    fileSizeBytes
    id
    number
    objectName
    source
    updatedAt
    rejectedAt
    rejectedBySystem
    rejectionDetails
    rejectionReason
  }

  contractorGeneralLiabilityInsurance {
    contentType
    contractorId
    createdAt
    documentType
    fileSizeBytes
    id
    objectName
    source
    updatedAt
    rejectedAt
    rejectedBySystem
    rejectionDetails
    rejectionReason
  }

  contractorWorkersCompInsurance {
    contentType
    contractorId
    createdAt
    documentType
    fileSizeBytes
    id
    objectName
    source
    updatedAt
    rejectedAt
    rejectedBySystem
    rejectionDetails
    rejectionReason
  }

  references {
    id
    name
    phoneNumber
    email
    type
    confirmedAt
    createdAt
    questionnaireId
    score
    scoreSubmittedAt
    scoreSubmittedById
    status
    updatedAt
    confirmationRequestedAt
  }

`;
