import styled from '@emotion/styled';
import {Text, Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {fadeRed, darkRed, darkGray, gray} from '@renofi/utils/src/colors';

import Button from '../Button';

export const UploadedFile = styled.div(
  mediaQuery({
    display: 'grid',
    gridTemplateColumns: ['30px auto auto', '30px 65% auto'],
    gap: '12px',
    padding: ['5px 10px', '10px 20px'],
    borderRadius: '8px',
  }),
  ({isRejected}) => ({
    background: isRejected ? fadeRed : '#FAFAFA',
    border: `1px solid ${isRejected ? darkRed : '#E9E9E9'}`,
    marginBottom: isRejected ? 20 : 0,
  }),
);

export const FileInfo = styled.div(
  mediaQuery({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    gridColumnStart: [2, 2],
    gridRowStart: [1, 1],
  }),
);

export const FileName = styled(Text)(
  mediaQuery({
    fontSize: '14px',
    lineHeight: '20px',
    color: darkGray,
    marginBottom: [0, '2px'],
    wordBreak: 'break-all',
  }),
);

export const FileStatus = styled(Text)(
  mediaQuery({
    fontSize: '12px',
    lineHeight: '14px',
  }),
  ({isRejected}) => ({
    textTransform: isRejected ? 'capitalize' : 'none',
    color: isRejected ? darkRed : gray,
  }),
);

export const TrashButton = styled(Button)(
  mediaQuery({
    padding: 0,
    flexBasis: '16px',
    height: '19px',
    marginLeft: ['5px', '10px'],
    marginBottom: [0, '5px'],
  }),
);

export const ButtonWrapper = styled(Box)(
  mediaQuery({
    gridColumnStart: [2, 3],
    gridRowStart: [2, 1],
    textAlign: ['left', 'left'],
  }),
);

export const Wrapper = styled(Box)(
  mediaQuery({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: ['flex-start', 'center'],
    minWidth: 55,
    gridColumnStart: [3, 3],
    gridRowStart: [1, 1],
  }),
);
