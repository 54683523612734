import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {darkBlue, fadeGray} from '@renofi/utils/src/colors';

import {secondaryHover} from '../Button/styled';

export const PanelWrapper = styled.div(
  mediaQuery({
    marginTop: 25,
    zIndex: 10,
    boxShadow: ['none', '0px 44.4792px 44.4792px rgba(68, 68, 68, 0.11)'],
  }),

  ({mobile, desktop, white, background, css}) =>
    mediaQuery({
      borderRadius: [0, 16],
      background: background ? background : white ? 'white' : fadeGray,
      display: [desktop ? 'none' : 'block', mobile ? 'none' : 'block'],
      ...css,
    }),
);

export const PanelHeader = styled.div(
  mediaQuery({
    borderRadius: '16px 16px 0 0',
    padding: ['10px 20px 15px 20px', '15px 25px'],
    background: 'white',
    fontSize: 24,
    color: 'black',
  }),

  ({dark, css}) =>
    mediaQuery({
      background: dark ? darkBlue : 'white',
      color: dark ? 'white' : 'none',
      fontWeight: dark ? 100 : ['normal', 'bold'],
      ...css,
    }),
);

export const PanelContent = styled.div(({css}) =>
  mediaQuery({
    padding: ['15px 15px 0 15px', '15px 15px 15px 15px'],
    ...css,
  }),
);

export const PanelWhite = styled(Flex)(
  mediaQuery({
    alignItems: 'center',
    textAlign: 'center',
    boxShadow: '0px 0px 7px rgba(0, 160, 255, 0.1);',
    border: '1px solid #F8F8F8',
    borderRadius: 8,
    zIndex: 1,
    background: 'white',
  }),
  ({selected, padding, noHover, css, disabled}) =>
    mediaQuery({
      cursor: disabled ? 'default' : 'pointer',
      ...(noHover ? {} : secondaryHover),
      ...(selected ? secondaryHover[':active'] : {}),
      padding: padding || '16px 24px',
      ...css,
    }),
);
