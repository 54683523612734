import {isNil} from 'ramda';

import isBrowser from './isBrowser';

const isBrowserEnv = isBrowser();

export const openTab = (url) => !isNil(url) && isBrowserEnv && window.open(url);

export const getDocument = () => isBrowserEnv && window.document;

export const getBody = () => getDocument()?.body;

export const getLocation = () => isBrowserEnv && window.location;

export const getChilipiper = () => isBrowserEnv && window.ChiliPiper;

export const getHostname = () => isBrowserEnv && window.location.hostname;

export const getDomain = () =>
  isBrowserEnv && window.location.hostname.split('.').slice(-2).join('.');

export const getCrypto = () =>
  isBrowserEnv && (window?.msCrypto || window?.crypto);
