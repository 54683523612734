import styled from '@emotion/styled/macro';
import {Flex, Box} from 'rebass';

import {darkBlue} from '@renofi/utils/src/colors';
import {mediaQuery} from '@renofi/utils';

export const MODAL_ZINDEX = 10005;

export const Cover = styled.div(
  mediaQuery({
    width: '100%',
    height: '100%',
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgb(0,0,0)',
    zIndex: 10004,
    opacity: 0.65,
  }),
  ({visible}) => ({
    ...(visible ? {} : {display: 'none'}),
  }),
);

export const ModalWrapper = styled(Flex)(
  mediaQuery({
    zIndex: MODAL_ZINDEX,
    alignItems: 'center',
    paddingTop: [0, 36],
    paddingBottom: [0, 36],
    height: '100%',
    position: 'fixed',
    top: 0,
    scrollBehavior: 'smooth',
    width: ['100vw', 'auto'],
    flexDirection: 'column',
    overflow: ['hidden', 'scroll'],
  }),
  ({visible, css}) =>
    mediaQuery({
      margin: 'auto',
      left: 0,
      right: 0,
      ...(visible ? {} : {display: 'none'}),
      ...css,
    }),
);

export const ModalContainer = styled(Flex)(
  mediaQuery({
    position: 'relative',
    flexDirection: 'column',
    height: ['100%', 'auto'],
  }),
  ({width = 660}) =>
    mediaQuery({
      width: ['100%', width],
    }),
);

export const Header = styled(Box)(
  mediaQuery({
    justifyContent: 'space-between',
    width: '100%',
    fontSize: [18, 22],
  }),
  ({light, title}) =>
    mediaQuery({
      color: light ? darkBlue : 'white',
      background: light ? 'white' : darkBlue,
      textAlign: light ? 'center' : 'left',
      padding: [
        '15px 45px 15px 15px',
        light ? '20px 45px 16px 25px' : '25px 45px 25px 25px',
      ],
      ...(!title ? {padding: 'none'} : {}),
      borderRadius: [0, light ? '16px 16px 0 0' : '4px 4px 0 0'],
    }),
);

export const Content = styled.div(
  mediaQuery({
    background: 'white',
    width: '100%',
    height: ['100%', 'auto'],
    padding: 20,
    overflowY: ['scroll', 'hidden'],
  }),
  ({fullRadius, css, light}) => {
    const radius = light ? 16 : 4;
    return mediaQuery({
      borderRadius: [0, !fullRadius ? `0 0 ${radius}px ${radius}px` : radius],
      ...css,
    });
  },
);

export const Close = styled(Flex)(
  {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    right: 7,
    width: 40,
    height: 40,
    cursor: 'pointer',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '12px 12px',
    backgroundPosition: 'center center',
  },
  ({light}) => ({
    top: light ? 12 : 5,
    'svg g#close-icon': {
      fill: light ? '#A6ACB6' : '#00A0FF',
    },
  }),
);
