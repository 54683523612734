import {gql} from '@apollo/client';

import {contractor} from '../../partials';

export default gql`
  query contractorById($id: ID!) {
    contractorById(id: $id) {
      ${contractor}
    }
  }
`;
