import {gql} from '@apollo/client';

export default gql`
  mutation generateAuthToken($refreshToken: String!) {
    generateAuthToken(refreshToken: $refreshToken) {
      jwt
      refreshToken
    }
  }
`;
