import {gql} from '@apollo/client';

export default gql`
  query supportedMimeTypes {
    supportedMimeTypes {
      contentType
      extensions
    }
  }
`;
