const exponent = 3;
const pi = Math.PI;
const halfPi = pi / 2;

export const easePolyOut = (function custom(e) {
  e = +e;
  function polyOut(t) {
    return 1 - Math.pow(1 - t, e);
  }
  polyOut.exponent = custom;
  return polyOut;
})(exponent);

export var easePolyInOut = (function custom(e) {
  e = +e;
  function polyInOut(t) {
    return ((t *= 2) <= 1 ? Math.pow(t, e) : 2 - Math.pow(2 - t, e)) / 2;
  }
  polyInOut.exponent = custom;
  return polyInOut;
})(exponent);

export function easeSinOut(t) {
  return Math.sin(t * halfPi);
}
