export default `
  allowedCombinations
  cashDisbursementType
  id
  interestOnly
  isFirstLien
  lender {
    name
    code
    id
  }
  lenderId
  maxArvCltv
  maxLoanAmount
  maxPeakCltv
  maxPurchaseLtv
  minCreditScore
  minLoanAmount
  name
  productType
  rateType
  rates
  refinanceRequired
  repaymentPenalty
  repaymentPeriod
  maxDti
`;
