import {getLocation} from './window';

const LOCATION = getLocation();

export function getEnvironmentUrl(name, _port) {
  if (name && name !== 'login' && isRenofiPreview()) {
    return getRenofiPreviewUrl(name);
  }

  if (name && name !== 'login' && isDeployedUnderNetlifyDomain()) {
    return getNetlifyUrl(name);
  }

  const isDev = LOCATION?.hostname?.includes('renofi.local');
  const isTest = process.env.REACT_APP_ENV === 'test';

  const RENOFI_TLD = isDev ? 'renofi.local' : process.env.REACT_APP_RENOFI_TLD;
  const port = isDev ? `:${_port}` : '';
  const prefix = isDev || isTest ? 'http' : 'https';
  return `${prefix}://${name}.${RENOFI_TLD}${port}`;
}

export const isDeployedUnderNetlifyDomain = () => {
  return (
    LOCATION.origin &&
    !!LOCATION.origin.match(/renofi-[0-9a-z-_]+\.netlify\.app/)
  );
};

export const isRenofiPreview = () => {
  return (
    LOCATION.origin &&
    !!LOCATION.origin.match(
      /deploy-preview-[0-9]+\.[0-9a-z]+\.renofistaging\.app/,
    )
  );
};

export function getMainSiteUrl() {
  const isDev = LOCATION?.hostname?.includes('renofi.local');

  const ENV = process.env.REACT_APP_ENV;
  const domain = ENV === 'production' ? 'renofi.com' : 'renofistaging.com';
  return isDev ? 'http://localhost:1313' : `https://${domain}`;
}

export function getSalesforceUrl(objectId) {
  if (!objectId) return null;
  const ENV = process.env.REACT_APP_ENV;
  const subdomain = ENV === 'production' ? 'renofi' : 'renofi--test';
  return `https://${subdomain}.my.salesforce.com/${objectId}`;
}

export function getNetlifyUrl(name) {
  if (!name) return LOCATION.origin;
  return LOCATION.origin.replace(
    /renofi-staging-\w+/i,
    `renofi-staging-${name}`,
  );
}

export function getRenofiPreviewUrl(name) {
  if (!name) return LOCATION.origin;
  return LOCATION.origin.replace(
    /\w+\.renofistaging.app/i,
    `${name}.renofistaging.app`,
  );
}

export function redirectToLogin(suffix = '') {
  const loginAppUrl = getEnvironmentUrl('login', '4004');
  const url = [loginAppUrl, suffix].join('').trim();

  return LOCATION.assign(url);
}
