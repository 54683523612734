import styled from '@emotion/styled/macro';
import {Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {darkBlue} from '@renofi/utils/src/colors';

export default styled(Text)(
  mediaQuery({
    fontSize: 14,
    lineHeight: '18px',
    paddingTop: '5px',
    textAlign: 'left',
  }),
  ({css, color, ...props}) =>
    mediaQuery({
      ...css,
      color: color || darkBlue,
      ...props,
    }),
);
