import {gql} from '@apollo/client';

export const CONFIRM_REFERENCE_DETAILS = gql`
  mutation confirmReferenceDetails(
    $confirmDetailsCorrect: Boolean!
    $email: String!
    $name: String!
    $phoneNumber: String!
    $referenceId: ID!
    $questionnaireId: ID
    $token: String
  ) {
    confirmReferenceDetails(
      confirmDetailsCorrect: $confirmDetailsCorrect
      email: $email
      name: $name
      phoneNumber: $phoneNumber
      referenceId: $referenceId
      questionnaireId: $questionnaireId
      token: $token
    ) {
      questionnaire {
        references {
          id
          name
          phoneNumber
          email
          type
          confirmedAt
          createdAt
          questionnaireId
          score
          scoreSubmittedAt
          scoreSubmittedById
          status
          updatedAt
          confirmationRequestedAt
        }
      }
    }
  }
`;
