import React from 'react';

import PropTypes from 'prop-types';

const PercentIcon = ({fill}) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 26 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.892 6.58569C9.61342 6.58569 8.57324 7.63294 8.57324 8.92019C8.57324 10.2075 9.61342 11.2547 10.892 11.2547C12.1706 11.2547 13.2107 10.2075 13.2107 8.92019C13.2107 7.63294 12.1706 6.58569 10.892 6.58569ZM12.4727 8.92019C12.4727 9.79764 11.7635 10.5116 10.892 10.5116C10.0205 10.5116 9.31131 9.79764 9.31131 8.92019C9.31131 8.04274 10.0205 7.32877 10.892 7.32877C11.7635 7.32877 12.4727 8.04262 12.4727 8.92019Z"
        fill="#FF5253"
      />
      <path
        d="M17.5795 11.3787C16.3009 11.3787 15.2607 12.426 15.2607 13.7132C15.2607 15.0003 16.3009 16.0477 17.5795 16.0477C18.8582 16.0477 19.8984 15.0004 19.8984 13.7132C19.8984 12.4259 18.8581 11.3787 17.5795 11.3787ZM19.1602 13.7132C19.1602 14.5906 18.451 15.3046 17.5794 15.3046C16.7078 15.3046 15.9987 14.5906 15.9987 13.7132C15.9987 12.8356 16.7078 12.1216 17.5794 12.1216C18.4511 12.1217 19.1602 12.8356 19.1602 13.7132Z"
        fill="#FF5253"
      />
      <path
        d="M12.5632 16.3964L11.2844 16.6924L18.0142 5.87173C18.1214 5.69934 18.0695 5.47196 17.8983 5.36396C17.7269 5.25597 17.5013 5.30823 17.394 5.48062L10.6642 16.3014L10.3702 15.0139C10.3249 14.8157 10.1286 14.6918 9.93152 14.7374C9.73458 14.783 9.61157 14.9806 9.65684 15.179L10.2308 17.6924L12.7272 17.1145C12.9241 17.0689 13.0471 16.8713 13.0019 16.6728C12.9566 16.4746 12.7603 16.3508 12.5632 16.3964Z"
        fill="#FF5253"
      />
      <circle
        cx="14.1348"
        cy="11.5"
        r="10.5"
        stroke="#FF5253"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0.54 1.62"
      />
      <path
        d="M6.05769 12.9807H0L3.09615 19.3076L7.40385 17.423L6.05769 12.9807Z"
        fill={fill}
      />
      <path
        d="M4.81526 12.3418C4.7768 12.3177 4.73397 12.3015 4.68922 12.294C4.64446 12.2865 4.59868 12.2879 4.55447 12.2982C4.51027 12.3084 4.46853 12.3273 4.43164 12.3537C4.39475 12.3802 4.36343 12.4136 4.33949 12.4521L4.04472 12.9245C4.01278 12.8813 3.97122 12.8462 3.92334 12.822C3.87546 12.7978 3.82258 12.785 3.76891 12.7848C3.71685 12.7861 3.66576 12.7992 3.61949 12.8231C3.57321 12.847 3.53295 12.8811 3.50172 12.9227L3.20867 12.4539C3.18536 12.4141 3.15431 12.3793 3.11734 12.3518C3.08038 12.3242 3.03826 12.3043 2.99348 12.2933C2.94869 12.2823 2.90215 12.2804 2.85662 12.2877C2.81108 12.295 2.76747 12.3113 2.72837 12.3358C2.68927 12.3602 2.65547 12.3923 2.62897 12.43C2.60248 12.4678 2.58383 12.5105 2.57412 12.5555C2.56442 12.6006 2.56386 12.6472 2.57248 12.6925C2.5811 12.7378 2.59871 12.7809 2.62429 12.8193L3.4862 14.1984C3.51721 14.2479 3.56029 14.2887 3.6114 14.317C3.66251 14.3453 3.71997 14.3601 3.77839 14.3601C3.83681 14.3601 3.89427 14.3453 3.94538 14.317C3.99649 14.2887 4.03957 14.2479 4.07058 14.1984L4.93248 12.8193C4.95653 12.7804 4.97258 12.737 4.9797 12.6918C4.98682 12.6466 4.98486 12.6004 4.97395 12.556C4.96304 12.5115 4.94339 12.4697 4.91615 12.4329C4.8889 12.3962 4.85461 12.3652 4.81526 12.3418Z"
        fill="#FF5253"
      />
    </svg>
  );
};

PercentIcon.propTypes = {
  fill: PropTypes.string,
};

PercentIcon.defaulProps = {
  fill: 'white',
};

export default PercentIcon;
