import {gql} from '@apollo/client';

import {contractorEstimate as estimate} from '../../partials';

export default gql`
  query contractorEstimateById($contractorEstimateId: ID!) {
    contractorEstimateById(contractorEstimateId: $contractorEstimateId) {
      ${estimate}
    }
  }
`;
