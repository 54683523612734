import React from 'react';

import styled from '@emotion/styled/macro';
import {keyframes} from '@emotion/react';
import PropTypes from 'prop-types';

const blink = keyframes`
  0 % {transform: scale(1, 1);}
  2% {transform: scale(1, .3);}
  4% {transform: scale(1, 1);}
`;

const FaceIcon = ({animate}) => {
  const AnimatedPath = animate
    ? styled.path`
        transform-origin: center 20px;
        animation: ${blink} 4s infinite 1s ease-in-out;
      `
    : styled.path();

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="#212F49" />
      <circle
        cx="24"
        cy="24"
        r="22.8"
        fill="#212F49"
        stroke="#212F49"
        strokeWidth="2.4"
      />
      <AnimatedPath
        id={animate ? 'eye2' : ''}
        d="M33.5368 17.0528C33.5368 16.3901 34.0741 15.8528 34.7368 15.8528C35.3996 15.8528 35.9368 16.3901 35.9368 17.0528L33.5368 17.0528ZM33.5368 22.1055L33.5368 17.0528L35.9368 17.0528L35.9368 22.1055L33.5368 22.1055Z"
        fill="white"
      />
      <path
        d="M22.7998 27L22.7998 27.9L21.8998 27.9L21.8998 27L22.7998 27ZM21.8998 20.6527C21.8998 20.1557 22.3027 19.7527 22.7998 19.7527C23.2969 19.7527 23.6998 20.1557 23.6998 20.6527L21.8998 20.6527ZM21.8998 27L21.8998 20.6527L23.6998 20.6527L23.6998 27L21.8998 27ZM22.7998 26.1L25.7998 26.1L25.7998 27.9L22.7998 27.9L22.7998 26.1Z"
        fill="white"
      />
      <AnimatedPath
        id={animate ? 'eye' : ''}
        d="M12.0632 17.0528C12.0632 16.3901 12.6004 15.8528 13.2632 15.8528C13.9259 15.8528 14.4632 16.3901 14.4632 17.0528L12.0632 17.0528ZM12.0632 22.1055L12.0632 17.0528L14.4632 17.0528L14.4632 22.1055L12.0632 22.1055Z"
        fill="white"
      />
      <path
        d="M32.2101 30.1895C32.2101 33.0567 28.8969 35.6164 23.9996 35.6164C19.1023 35.6164 15.7891 33.3753 15.7891 30.1895"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
      />
    </svg>
  );
};

FaceIcon.propTypes = {
  animate: PropTypes.bool,
};

export default FaceIcon;
