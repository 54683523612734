import styled from '@emotion/styled/macro';
import {Text as RebassText, Link as RebassLink, Box as RebassBox} from 'rebass';

import {blue, gray} from '@renofi/utils/src/colors';
import {mediaQuery} from '@renofi/utils';

export const Link = styled(RebassLink)(
  mediaQuery({
    display: 'inline',
    color: blue,
    marginTop: 10,
    fontSize: 14,
    cursor: 'pointer',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
    },
  }),
);

export const LicensingLink = styled.span(
  mediaQuery({
    display: ['block', 'inline'],
    '&:before': {
      content: ['""', '" | "'],
    },
  }),
);

export const Box = styled(RebassBox)(
  mediaQuery({
    textAlign: ['center', 'left'],
    marginTop: 30,
    marginLeft: -2,
    marginRight: -2,
  }),
  ({center, props}) =>
    mediaQuery({
      textAlign: ['center', center ? 'center' : 'left'],
      ...props,
    }),
);

export const Text = styled(RebassText)(
  mediaQuery({
    fontSize: 14,
  }),
);

export const TextRight = styled(Text)({}, ({center}) =>
  mediaQuery({
    textAlign: ['center', center ? 'center' : 'right'],
  }),
);

export const LegalMessage = styled(Text)({}, ({center}) =>
  mediaQuery({
    color: gray,
    lineHeight: '20px',
    margin: '10px 0',
    maxWidth: 800,
    textAlign: ['center', center ? 'center' : 'right'],
  }),
);
