import {gql} from '@apollo/client';

import {user} from '../../partials';

export default gql`
  mutation authenticateByLoginToken($redirectUrl: String, $token: String!) {
    authenticateByLoginToken(redirectUrl: $redirectUrl, token: $token) {
      jwt
      refreshToken
      user {
        ${user}
      }
    }
  }
`;
