import React from 'react';

import {Flex} from 'rebass';
import PropTypes from 'prop-types';

import {Button} from '@renofi/components';

import {ReactComponent as SubmitIcon} from './img/submit.svg';

const MessageSubmit = ({onClick}) => {
  return (
    <Flex width={70} justifyContent="center">
      <Button transparent onClick={onClick}>
        <SubmitIcon />
      </Button>
    </Flex>
  );
};

MessageSubmit.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default MessageSubmit;
