import {gql} from '@apollo/client';

import {daasClient} from '../../partials';

export default gql`
  mutation referClientForDaas($client: ContractorClientInputObject!, $contractorId: ID!) {
    referClientForDaas(client: $client, contractorId: $contractorId) {
      daasClient {
        ${daasClient}
      }
    }
  }
`;
