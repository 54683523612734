import {gql} from '@apollo/client';

export const CONFIRM_CREDIT_LIABILITIES = gql`
  mutation confirmCreditLiabilities(
    $creditLiabilitiesIds: [ID!]
    $softCreditCheckId: ID!
  ) {
    confirmCreditLiabilities(
      creditLiabilitiesIds: $creditLiabilitiesIds
      softCreditCheckId: $softCreditCheckId
    ) {
      success
    }
  }
`;
