import {gql} from '@apollo/client';

export const UPDATE_CONTRACTOR_INSURANCE = gql`
  mutation updateContractorInsurance(
    $documentType: DocumentTypeEnum!
    $expiryDate: Date
    $hasWorkersCompInsurance: Boolean
    $noInsuranceReasons: [NoInsuranceReasonEnum!]
    $noInsuranceReasonsOther: String
    $questionnaireId: ID
    $token: String
    $workersCompIncludedInGlid: Boolean
  ) {
    updateContractorInsurance(
      documentType: $documentType
      expiryDate: $expiryDate
      hasWorkersCompInsurance: $hasWorkersCompInsurance
      noInsuranceReasons: $noInsuranceReasons
      noInsuranceReasonsOther: $noInsuranceReasonsOther
      questionnaireId: $questionnaireId
      token: $token
      workersCompIncludedInGlid: $workersCompIncludedInGlid
    ) {
      questionnaire {
        id
      }
    }
  }
`;
