import {gql} from '@apollo/client';

export const ELIGIBLE_PRODUCTS = gql`
  query eligibleProducts(
    $criteria: EligibleProductsCriteriaInputObject!
    $zipCode: String!
    $onlyRenofiProducts: Boolean
  ) {
    eligibleProducts(
      criteria: $criteria
      zipCode: $zipCode
      onlyRenofiProducts: $onlyRenofiProducts
    ) {
      maxLoanAmountOffered
      minMonthlyPayment
      refinancedMonthlyMortgagePayments
      renovationFractionMinMonthlyPayment
      offeredLoanAmountExcludingRefinance
      product {
        cashDisbursementType
        id
        interestOnly
        lender {
          name
          code
          id
        }
        lenderId
        maxArvCltv
        maxLoanAmount
        maxPeakCltv
        minCreditScore
        minLoanAmount
        name
        productType
        rateType
        rates
        refinanceRequired
        repaymentPenalty
        repaymentPeriod
        maxDti
      }
    }
  }
`;
