import React from 'react';

import PropTypes from 'prop-types';
import {Text} from 'rebass';

import {
  capitalizeFirstLetter,
  getTimePassedFromDateTillNow,
  gray,
} from '@renofi/utils';

const Timestamp = ({timestamp, show}) => {
  if (!show) return null;

  const timeFromNow = getTimePassedFromDateTillNow(timestamp);

  return (
    <Text pl={54} mt="-8px" mb={16} fontSize={12} color={gray} textAlign="left">
      {capitalizeFirstLetter(timeFromNow)}
    </Text>
  );
};

Timestamp.propTypes = {
  timestamp: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default Timestamp;
