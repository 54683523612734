import logger from 'loglevel';

import {isRenofiPreview} from './environment';

logger.setDefaultLevel(logger.levels.WARN);
if (process.env.NODE_ENV === 'development' || isRenofiPreview()) {
  logger.setLevel(logger.levels.DEBUG);
}

export default logger;
