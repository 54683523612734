import {cssTransition, toast as showToast} from 'react-toastify';

import {CheckIcon} from '../Icons';

import {ToastContainer} from './styled';

const slide = cssTransition({
  enter: 'slide-top',
  exit: 'slide-bottom',
});

export const toast = {
  success: (context, options) =>
    showToast(context, {icon: <CheckIcon />, ...options}),
};

const Toast = ({autoClose = 3000}) => {
  return (
    <ToastContainer
      transition={slide}
      position="bottom-center"
      autoClose={autoClose}
      closeButton={false}
      hideProgressBar
    />
  );
};

export default Toast;
