import {noop} from 'lodash';

import {blue} from '@renofi/utils';

const CloseIcon = ({size = 16, fill = blue, onClick, ...props}) => {
  return (
    <svg
      onClick={onClick ? onClick : noop}
      style={{cursor: Boolean(onClick) ? 'pointer' : 'auto'}}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      version="1.1"
      {...props}>
      <desc>Created with Sketch.</desc>
      <g
        id="Assets"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          transform="translate(-207.000000, -844.000000)"
          fill={fill}
          id="close-icon">
          <polygon points="223 845.611429 221.388571 844 215 850.388571 208.611429 844 207 845.611429 213.388571 852 207 858.388571 208.611429 860 215 853.611429 221.388571 860 223 858.388571 216.611429 852"></polygon>
        </g>
      </g>
    </svg>
  );
};

export default CloseIcon;
