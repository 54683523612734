import React from 'react';

import PropTypes from 'prop-types';

import {PanelWhite} from '../Panel';
import Toggle from '../Toggle';

import CheckboxTitle from './CheckboxTitle';

function CheckboxPanel({
  label,
  children,
  onToggle,
  value,
  keepHover,
  disabled,
  radio,
  greyOut,
}) {
  function onCheckboxToggle() {
    onToggle(!value);
  }

  return (
    <>
      <CheckboxTitle
        onToggle={onCheckboxToggle}
        label={label}
        value={value}
        keepHover={keepHover}
        disabled={disabled}
        radio={radio}
        greyOut={greyOut}
      />

      <Toggle show={Boolean(children && value)}>
        <PanelWhite
          noHover
          padding="24px 16px 16px 16px"
          mt="-8px"
          flexDirection="column"
          css={{position: 'relative', zIndex: 0, cursor: 'default'}}>
          {children}
        </PanelWhite>
      </Toggle>
    </>
  );
}

CheckboxPanel.propTypes = {
  label: PropTypes.node.isRequired,
  children: PropTypes.node,
  onToggle: PropTypes.func,
  value: PropTypes.bool,
  keepHover: PropTypes.bool,
  disabled: PropTypes.bool,
  radio: PropTypes.bool,
  greyOut: PropTypes.bool,
};

export default CheckboxPanel;
