import {gql} from '@apollo/client';

export const QUESTIONNAIRE_UPLOAD_METADATA = gql`
  query questionnaireUploadMetadata(
    $contentType: String!
    $fileName: String!
    $questionnaireId: ID
    $token: String
  ) {
    result: questionnaireUploadMetadata(
      contentType: $contentType
      fileName: $fileName
      questionnaireId: $questionnaireId
      token: $token
    ) {
      basePath
      contentType
      expires
      fileName
      httpMethod
      objectName
      recordId
      url
    }
  }
`;
