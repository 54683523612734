import styled from '@emotion/styled/macro';
import {Flex, Button} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {
  red,
  lightGray,
  fadeGray,
  fadeBlue,
  gray,
  blue,
} from '@renofi/utils/src/colors';

export const StyledButton = styled(Button)(
  mediaQuery({
    WebkitTapHighlightColor: 'transparent',
    position: 'relative',
    borderRadius: 50,
    ':focus': {
      outline: 'none',
    },
  }),
  ({
    disabled,
    disabledBgColor,
    disabledColor,
    small,
    xSmall,
    xxSmall,
    large,
    color,
    bgColor,
    borderColor,
    withIcon,
    noHover,
    width,
    noCaps,
    transparent,
    secondary,
    internal,
    css,
    loading,
  }) =>
    mediaQuery({
      width: width ? width : ['100%', 'auto'],
      cursor: disabled ? 'default' : 'pointer',
      opacity: disabled ? 0.7 : 1,
      background: (() => {
        if (disabled) return disabledBgColor || fadeGray;
        if (secondary) return 'white';
        if (transparent) return 'none';
        if (internal) return blue;
        if (bgColor) return bgColor;
        return red;
      })(),
      border: (() => {
        if (disabled) return 'solid 1px transparent';
        if (borderColor || color)
          return `solid 1px ${borderColor || color || 'white'}`;
        if (transparent) return 'none';
        if (secondary) return `solid 1px ${lightGray}`;
        return red;
      })(),
      fontSize: (() => {
        if (noCaps) return 18;
        if (xxSmall) return 10;
        if (xSmall) return 12;
        if (small) return 14;
        if (large) return 18;
        return 16;
      })(),
      lineHeight: (() => {
        if (xxSmall) return '19px';
        return '22px';
      })(),
      color: (() => {
        if (loading && xxSmall) return 'transparent';
        if (disabled && disabledColor) return disabledColor;
        if (disabled) return lightGray;
        if (secondary) return gray;
        return color || 'white';
      })(),
      height: (() => {
        if (xxSmall) return 24;
        if (xSmall) return 34;
        if (small) return 44;
        if (large) return 60;
        return 50;
      })(),
      padding: (() => {
        if (transparent) return `4px 10px 3px 10px`;
        if (xxSmall) return `2px ${withIcon ? 40 : 12}px 2px 12px`;
        if (xSmall) return `4px ${withIcon ? 40 : 20}px 3px 20px`;
        if (small) return `8px ${withIcon ? 55 : 25}px 8px 25px`;
        return [
          `13px ${withIcon ? 60 : 25}px 13px 25px`,
          `13px ${withIcon ? 60 : 35}px 13px 35px`,
        ];
      })(),
      ':active': {
        boxShadow:
          transparent || disabled ? 'none' : 'inset 0px 0px 6px #00000053',
      },
      ':hover': (() => {
        if (disabled) return {};
        if (secondary) return secondaryHover;
        return {
          filter: ['none', noHover ? 'none' : 'brightness(0.95)'],
        };
      })(),
      textTransform: noCaps ? 'none' : 'uppercase',
      transition: secondary ? 'none' : 'color, background .3s',
      ...css,
    }),
);

export const secondaryHover = {
  ':hover': [
    'none',
    {
      borderColor: '#80CFFF',
    },
  ],
  ':active': {
    boxShadow: 'none',
    borderColor: '#80CFFF',
    background: [fadeBlue, fadeBlue],
  },
};

export const IconWrapper = styled(Flex)(
  {
    alignItems: 'center',
    height: '100%',
    top: -1,
    marginTop: 0,
    pointerEvents: 'none',
  },
  ({small, xSmall, xxSmall, secondary, center}) => {
    let scale = 1;
    if (xxSmall) scale = 0.5;
    if (xSmall) scale = 0.65;
    if (small) scale = 0.85;

    return {
      position: center ? 'static' : 'absolute',
      right: (() => {
        if (xSmall) return 8;
        if (small) return 10;
        return 20;
      })(),
      transform: `scale(${scale})`,
      g: {...(secondary ? {fill: gray} : {})},
      path: {...(secondary ? {fill: gray} : {})},
    };
  },
);
