import {gql} from '@apollo/client';

export default gql`
  query currentUser {
    result: user {
      id
      accountCategories
      active
      allAgreementsAcceptedAt
      contractorId
      currentAgreements {
        acceptedAt
        ipAddress
        name
        version
      }
      email
      firstName
      lastName
      phoneNumber
    }
  }
`;
