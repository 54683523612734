export default `
  id
  contractorId
  normalizedEntityType
  entityId
  entityFullName
  entityPropertyAddress
  entityProjectStatus
  entityDesignStatus
  entityLeadStatus
  entityRenovationCost
  entityProjectActionStatus
  createdAt
  updatedAt
`;
