import {capitalize, isNil, isNumber} from 'lodash';

export const toCurrency = (
  value,
  {currency = 'en-US', prefix = false} = {},
) => {
  if (!isNumber(value)) return value;
  const finalValue = Math.round(Number(value)).toLocaleString(currency);

  return prefix ? `${prefix}${finalValue}` : finalValue;
};

export const toShortCurrency = (value) => {
  if (isNil(value) || value === '') return value;
  const symbols = ['', 'k', 'M', 'B', 'T']; // Suffixes for thousands, millions, billions, trillions, etc.

  let symbolIndex = 0;
  let formattedValue = value;

  // Divide the value by 1000 until it's less than 1000 or we run out of suffixes
  while (formattedValue >= 1000 && symbolIndex < symbols.length - 1) {
    formattedValue /= 1000;
    symbolIndex++;
  }

  // Round the value to a maximum of two decimal places
  formattedValue = Math.round(formattedValue * 100) / 100;

  // Convert the formatted value back to a string and append the suffix
  return `${formattedValue}${symbols[symbolIndex]}`;
};

export const toPercent = (value, minimumFractionDigits = 0) => {
  if (isNil(value) || value === '') return value;
  return Number(value).toLocaleString('en', {
    style: 'percent',
    minimumFractionDigits,
  });
};

export const getPercent = (percent, total) => {
  return Number(((percent / 100) * total).toFixed(2));
};

export const getPercentFrom = (partial, total, precision = null) => {
  const result = (100 * partial) / total;

  if (precision) {
    return Number(result).toFixed(precision);
  }

  return Math.round(result);
};

export function digitsOnly(strValue) {
  return /^\d+$/.test(strValue);
}

export function stringifyNumber(value) {
  const strings = ['0th', '1st', '2nd', '3rd', '4th', '5th'];
  return strings[value];
}

export function stringifyObject(object) {
  Object.keys(object).forEach((key) => {
    if (typeof object[key] === 'object') {
      return stringifyObject(object[key]);
    }
    object[key] = '' + object[key];
  });
  return object;
}

export function humanizeCase(string = '', fallback = '') {
  if (isNil(string)) {
    return fallback;
  }

  return capitalize(
    string
      .replace(/([A-Z])/g, ' $1')
      .replace(/-|_/gi, ' ')
      .trim()
      .toLowerCase(),
  );
}

export function snakeToCamel(str) {
  return str.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace('-', '').replace('_', ''),
  );
}

export function snakeToDashed(str) {
  return str.replace('_', '-');
}

export function capitalizeFirstLetter(str) {
  if (typeof str !== 'string') return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isMonoCharacterString(character, str) {
  const regex = new RegExp(`^(${character})+$`);
  return regex.test(str);
}

export function stringifyUrlParams() {
  return new URLSearchParams(window.location.search).toString();
}

export function isBooleanString(value) {
  return value === 'true' || value === 'false';
}

export const removeNonNumeric = (string) => {
  return string.replace(/\D/g, '');
};

export const roundToNextMillion = (value) =>
  Math.ceil(value / 1000000) * 1000000;

export const roundToBaseMillion = (value) =>
  roundToNextMillion(value) - 1000000;

export const formatAddress = (lead, options) => {
  const address = [
    options?.short ? lead?.streetAddressShort : lead?.streetAddress,
    lead?.city,
    lead?.state,
    lead?.zipCode,
  ].filter(Boolean);

  return address?.join(', ');
};

export const formatName = (userObj, fallback = '') => {
  const {firstName, lastName, middleName} = userObj || {};
  if (!firstName && !lastName) {
    return fallback;
  }
  return [firstName, middleName, lastName]
    .filter((item) => Boolean(item))
    .join(' ')
    .trim();
};

export const formatPhoneNumber = (
  phoneNumberString,
  {stripCountryCode = false} = {},
) => {
  if (stripCountryCode) {
    phoneNumberString = String(phoneNumberString).replace(/^\+1\s?/, '');
  }
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return phoneNumberString;
};
