import {gql} from '@apollo/client';

import {user} from '../../partials';

export default gql`
  mutation authenticate($email: String!, $password: String!) {
    authenticate(auth: {email: $email, password: $password}) {
      user {
        ${user}
      }
      jwt
      refreshToken
    }
  }
`;
