import styled from '@emotion/styled/macro';
import {Box, Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {red} from '@renofi/utils/src/colors';

export const Background = styled(Box)(
  {
    width: '100%',
    position: 'absolute',
    zIndex: 1,
    bottom: 0,
  },
  ({height, bgColor}) => ({
    height: height,
    background: bgColor ? bgColor : '#E8E8E8',
    borderRadius: height,
  }),
);

export const ProgressWrapper = styled(Flex)({
  width: '100%',
  position: 'relative',
  zIndex: 2,
});

export const BarWrapper = styled(Flex)(({progress}) => ({
  width: `${Math.min(progress, 100)}%`,
  flexDirection: 'column',
  alignItems: 'flex-end',
  zIndex: 2,
  transition: 'width .3s .10s',
}));

export const Bar = styled(Box)(
  {
    width: '100%',
    background: red,
  },
  ({height}) => ({
    height: height,
    borderRadius: height,
  }),
);

export const Title = styled(Box)(
  mediaQuery({
    position: 'absolute',
    fontSize: [12, 16],
    fontWeight: 300,
    top: 0,
    width: '100%',
  }),
);

export const IconWrapper = styled(Box)(
  mediaQuery({
    position: 'absolute',
    fontSize: [12, 16],
    fontWeight: 300,
    top: -23,
    width: '100%',
  }),
);
