import styled from '@emotion/styled';
import {animated} from '@react-spring/web';
import {Flex} from 'rebass';

export const Invitation = styled(animated.div)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  padding: 36,
  marginRight: 12,
  cursor: 'pointer',
}));

export const Close = styled(Flex)(() => ({
  position: 'absolute',
  zIndex: 1000001,
  alignItems: 'center',
  justifyContent: 'center',
  top: '12px',
  right: '12px',
  background: 'white',
  width: 40,
  height: 40,
  cursor: 'pointer',
  borderRadius: 100,
  boxShadow: '0px 0px 5px 0px #00000024',
  g: {
    fill: '#1C1B1F',
  },
}));
