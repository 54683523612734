import {gql} from '@apollo/client';

import {contractor} from '../../partials';

export default gql`
  mutation updateContractor($contractor: ContractorInputObject!, $daasToken: String) {
    updateContractor(contractor: $contractor, daasToken: $daasToken) {
      contractor {
        ${contractor}
      }
    }
  }
`;
