import {gql} from '@apollo/client';

export const GET_CONTRACTOR_LOGO_BY_SLUG = gql`
  query getContractorBySlug($urlSlug: String!) {
    contractorBySlug(urlSlug: $urlSlug) {
      businessName
      city
      logoUrl
      daasPrice
      state
    }
  }
`;
