import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';

import {CloudIcon} from '../Icons';

import {ReactComponent as TreeIcon} from './img/tree.svg';

export const Tree = styled(TreeIcon)(
  mediaQuery({
    position: 'absolute',
  }),
  ({top, left, right, scale = 1}) => ({
    top,
    left: left ? `calc(50% - ${left}px)` : 'inherit',
    right: right ? `calc(50% - ${right}px)` : 'inherit',
    transform: `scale(${scale})`,
  }),
);

export const Cloud = styled(CloudIcon)(
  mediaQuery({
    position: 'absolute',
  }),
  ({top, left, right}) => ({
    top,
    left: left ? `calc(50% - ${left}px)` : 'inherit',
    right: right ? `calc(50% - ${right}px)` : 'inherit',
  }),
);

export const hillBg = (top = [300, 400]) => ({
  position: 'absolute',
  zIndex: -1,
  content: '" "',
  width: '900vw',
  height: '900vw',
  left: '50%',
  top,
  transform: 'translateX(-50%)',
  borderRadius: 10000000,
  background: 'linear-gradient(180deg, #E3F9E5 0%, #C2F0C7 10%)',
});

export const Grass = styled(Box)(
  mediaQuery({
    position: 'absolute',
    zIndex: 0,
    width: '100%',
    minHeight: '100%',
    overflow: 'hidden',
    background:
      'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(229,245,255,1) 470px)',
    ':after': hillBg(),
  }),
  ({css}) => mediaQuery(css),
);
