import {gql} from '@apollo/client';

export const UPDATE_SCENARIO_PERSONAL = gql`
  mutation updateScenarioPersonal(
    $email: String
    $firstName: String
    $id: ID!
    $lastName: String
    $location: LocationInputObject
    $phone: String
    $zipCode: String
  ) {
    updateScenarioPersonal(
      email: $email
      firstName: $firstName
      id: $id
      lastName: $lastName
      location: $location
      phone: $phone
      zipCode: $zipCode
    ) {
      lead {
        id
        estimatedHomeValue
      }
    }
  }
`;
