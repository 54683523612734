import {useState} from 'react';

import PropTypes from 'prop-types';
import {Box} from 'rebass';

import {Table as StyledTable} from './styled';

const Table = (props) => {
  const [showSwipeIcon, setShowSwipeIcon] = useState(props.responsive);

  function onScroll() {
    setShowSwipeIcon(false);
  }

  return (
    <Box
      onScroll={onScroll}
      width={1}
      css={{overflowX: props.responsive ? 'scroll' : 'auto'}}>
      <StyledTable {...props} showSwipeIcon={showSwipeIcon} />
    </Box>
  );
};

Table.propTypes = {
  css: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  responsive: PropTypes.bool,
  showSwipeIcon: PropTypes.bool,
  horizontal: PropTypes.bool,
};

export default Table;
