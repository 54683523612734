import {gql} from '@apollo/client';

export const SOFT_CREDIT_CHECK_BY_ID = gql`
  query softCreditCheckById($id: ID!) {
    softCreditCheckById(id: $id) {
      chosenCreditScore {
        numericValue
        modelName
        source
        value
      }
      creditScores {
        numericValue
        modelName
        source
        value
      }
      mortgageLiabilities {
        creditorName
        loanType
        id
        unpaidBalance
      }
      mortgageLiabilitiesNeedConfirmation
      status
      receivedAt
      id
    }
  }
`;
