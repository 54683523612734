import styled from '@emotion/styled';
import {Flex, Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {fadeGray, blue, lightBlue, lightGray} from '@renofi/utils/src/colors';

export const Wrapper = styled(Flex)(({disabled}) => ({
  width: '100%',
  pointerEvents: disabled ? 'none' : 'auto',
  outline: 'none',
}));

export const Placeholder = styled(Flex)(
  mediaQuery({
    flex: 1,
    flexDirection: 'column',
    gap: 8,
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px dashed green`,
    borderWidth: 2,
    borderRadius: 3,
    backgroundColor: fadeGray,
    backgroundSize: '204px 85px',
    backgroundPosition: '36px 32px',
    backgroundRepeat: 'no-repeat',
  }),
);

export const Message = styled(Text)({
  fontSize: 19,
  lineHeight: 1,
  color: 'rgba(0,0,0,0.4)',
  margin: '10px 0',
});

export const Details = styled(Text)({
  fontSize: 15,
  lineHeight: 1,
  color: 'rgba(0,0,0,0.55)',
  margin: '10px 0',
});

export const Content = styled(Flex)(({css}) =>
  mediaQuery({
    flex: 1,
    alignItems: 'center',
    gap: 8,
    border: '2px dashed #D8D8D8',
    justifyContent: 'center',
    padding: ['15px', '20px'],
    borderRadius: '8px',
    cursor: 'pointer',

    ':hover': {
      borderColor: lightBlue,
      backgroundColor: 'rgba(229, 245, 255, 0.6)',
    },
    ...css,
  }),
);

export const UploadText = styled(Text)(({disabled}) => ({
  color: disabled ? lightGray : blue,
  fontSize: 14,
}));
