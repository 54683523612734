import {gql} from '@apollo/client';

export const REQUEST_SOFT_CREDIT_CHECK = gql`
  mutation requestSoftCreditCheck(
    $authorizedRenofiToVerifyCredit: Boolean!
    $dateOfBirth: Date!
    $firstName: String!
    $lastName: String!
    $residentialAddressCity: String!
    $residentialAddressState: String!
    $residentialAddressStreet: String!
    $residentialAddressZip: String!
    $scenarioId: ID!
  ) {
    requestSoftCreditCheck(
      authorizedRenofiToVerifyCredit: $authorizedRenofiToVerifyCredit
      dateOfBirth: $dateOfBirth
      firstName: $firstName
      lastName: $lastName
      residentialAddressCity: $residentialAddressCity
      residentialAddressState: $residentialAddressState
      residentialAddressStreet: $residentialAddressStreet
      residentialAddressZip: $residentialAddressZip
      scenarioId: $scenarioId
    ) {
      softCreditCheck {
        id
      }
    }
  }
`;
