import {gql} from '@apollo/client';

export default gql`
  query contractorBySlug($urlSlug: String!) {
    contractorBySlug(urlSlug: $urlSlug) {
      businessName
      logoUrl
      urlSlug
    }
  }
`;
