import {gql} from '@apollo/client';

export default gql`
  mutation shareFinancingEstimateViaEmail(
    $contractorEstimateId: ID!
    $financingEstimateEmailInput: FinancingEstimateEmailInputObject!
    $loanProductMatches: [LoanProductMatchInputObject!]
  ) {
    shareFinancingEstimateViaEmail(
      contractorEstimateId: $contractorEstimateId
      financingEstimateEmailInput: $financingEstimateEmailInput
      loanProductMatches: $loanProductMatches
    ) {
      financingEstimateEmail {
        attachments
        ccEmails
        contractorEstimateId
        createdAt
        id
        introduction
        message
        subject
        updatedAt
      }
    }
  }
`;
