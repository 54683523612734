/* eslint-disable max-lines */
import styled from '@emotion/styled/macro';
import {keyframes} from '@emotion/react';
import {Text, Image, Box, Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';

import copyBg from './img/copyBg.svg';
import copyBgHorizontal from './img/copyBgHorizontal.svg';

export const ContentBox = styled(Box)(
  mediaQuery({
    position: 'static',
    bottom: '-10px',
    padding: ['20px 0', '0'],
  }),
);

export const MessageFlex = styled(Flex)(
  mediaQuery({
    cursor: 'pointer',
    justifyContent: 'flex-end',
    marginRight: '270px',
    marginTop: '10px',
  }),
);

export const StaticImagesFlex = styled(Flex)(
  mediaQuery({
    justifyContent: ['center', 'flex-end'],
    marginRight: '[0, 50px]',
  }),
);

export const ImagesFlex = styled(Flex)(
  mediaQuery({
    cursor: 'pointer',
    justifyContent: ['center', 'flex-end'],
    marginRight: '[0, 50px]',
  }),
  ({fullHeight}) => ({
    marginBottom: fullHeight ? 20 : -70,
  }),
);

const bounceIn = keyframes({
  '30%': {
    opacity: '0',
    transform: 'scale(.3)',
  },
  '80%': {
    opacity: '1',
    transform: 'scale(1.05)',
  },
  '90%': {transform: 'scale(.9)'},
  '100%': {transform: 'scale(1)'},
});

const bounce1 = keyframes({
  '0%': {
    transform: 'translateY(2000px)',
  },
  '20%': {
    transform: 'translateY(0)',
  },
  '50%': {
    transform: 'translateY(0)',
  },
  '80%': {
    transform: 'translateY(0)',
  },
  '100%': {
    transform: 'translateY(0)',
  },
  '40%': {
    transform: 'translateY(-10px)',
  },
  '60%': {
    transform: 'translateY(-5px)',
  },
});

const bounce2 = keyframes({
  '5%': {
    transform: 'translateY(2000px)',
  },
  '25%': {
    transform: 'translateY(0)',
  },
  '55%': {
    transform: 'translateY(0)',
  },
  '85%': {
    transform: 'translateY(0)',
  },
  '100%': {
    transform: 'translateY(0)',
  },
  '45%': {
    transform: 'translateY(-10px)',
  },
  '65%': {
    transform: 'translateY(-5px)',
  },
});

const bounce3 = keyframes({
  '10%': {
    transform: 'translateY(2000px)',
  },
  '30%': {
    transform: 'translateY(0)',
  },
  '60%': {
    transform: 'translateY(0)',
  },
  '90%': {
    transform: 'translateY(0)',
  },
  '100%': {
    transform: 'translateY(0)',
  },
  '50%': {
    transform: 'translateY(-10px)',
  },
  '70%': {
    transform: 'translateY(-5px)',
  },
});

const bounce4 = keyframes({
  '15%': {
    transform: 'translateY(2000px)',
  },
  '35%': {
    transform: 'translateY(0)',
  },
  '65%': {
    transform: 'translateY(0)',
  },
  '95%': {
    transform: 'translateY(0)',
  },
  '100%': {
    transform: 'translateY(0)',
  },
  '55%': {
    transform: 'translateY(-10px)',
  },
  '75%': {
    transform: 'translateY(-5px)',
  },
});

const bounce5 = keyframes({
  '20%': {
    transform: 'translateY(2000px)',
  },
  '40%': {
    transform: 'translateY(0)',
  },
  '70%': {
    transform: 'translateY(0)',
  },
  '100%': {
    transform: 'translateY(0)',
  },
  '60%': {
    transform: 'translateY(-10px)',
  },
  '80%': {
    transform: 'translateY(-5px)',
  },
});

export const AvatarImage = styled(Image)(({sm}) =>
  mediaQuery({
    width: sm ? 53.5 : 64,
    height: sm ? 53.5 : 64,
    marginTop: 4,
    marginLeft: ['-25px', '-14px'],
  }),
);

const swing1 = keyframes({
  '10%': {transform: 'translateX(0%)'},
  '25%': {transform: 'translateX(-25%)'},
});

const swing2 = keyframes({
  '15%': {transform: 'translateX(0%)'},
  '30%': {transform: 'translateX(-25%)'},
});

const swing3 = keyframes({
  '20%': {transform: 'translateX(0%)'},
  '35%': {transform: 'translateX(-25%)'},
});

const swing4 = keyframes({
  '25%': {transform: 'translateX(0%)'},
  '40%': {transform: 'translateX(-25%)'},
});

const swing5 = keyframes({
  '30%': {transform: 'translateX(0%)'},
  '45%': {transform: 'translateX(-10%)'},
});

const swing6 = keyframes({
  '10%': {transform: 'translateX(0%)'},
  '25%': {transform: 'translateX(-10%)'},
});

const avatarStyle = {
  border: 'solid 2px white',
  borderRadius: 200,
  width: 64,
  height: 64,
};

export const MimiImage = styled(AvatarImage)({
  ...avatarStyle,
  borderRadius: 200,
  marginLeft: '0px',
  '&.mimi-bounce': {animation: `${bounce1} 3s ease 1`},
  '&.mimi-swing': {
    transformOrigin: 'top center',
    animation: `${swing1} 2s linear 1`,
  },
});

export const KylieImage = styled(AvatarImage)({
  ...avatarStyle,
  '&.kylie-bounce': {animation: `${bounce2} 3s ease 1`},
  '&.kylie-swing': {
    transformOrigin: 'top center',
    animation: `${swing2} 2s linear 1`,
  },
});

export const LeeImage = styled(AvatarImage)({
  ...avatarStyle,
  position: 'relative',
  zIndex: 1,
  '&.lee-bounce': {animation: `${bounce3} 3s ease 1`},
  '&.lee-swing': {
    transformOrigin: 'top center',
    animation: `${swing3} 2s linear 1`,
  },
});

export const OtherAdvisorsImage = styled(AvatarImage)({
  '&.advisors-bounce': {animation: `${bounce4} 3s ease 1`},
  '&.advisors-swing': {
    transformOrigin: 'top center',
    animation: `${swing4} 2s linear 1`,
  },
});

export const OnlineMessage = styled(Box)({
  padding: '10px',
  '&.online-message-bounce': {animation: `${bounce5} 3s ease 1`},
  '&.online-message-swing': {
    transformOrigin: 'top center',
    animation: `${swing5} 2s linear 1`,
  },
});

export const OnlineImage = styled(Image)({
  padding: '5px 5px 5px 0',
});

export const AdvisorText = styled(Text)(
  mediaQuery({
    width: 250,
    height: 100,
    padding: '20px 0 0 30px',
    textAlign: 'left',
    lineHeight: '19px',
    background: `url(${copyBg}) no-repeat`,
    backgroundSize: 'contain',
    animation: `${bounceIn} 3s ease 1`,
    '&.advisor-text-bounce': {animation: `${bounceIn} 2s ease 1`},
    '&.advisor-text-swing': {
      transformOrigin: 'top center',
      animation: `${swing6} 2s linear 1`,
    },
  }),
);

export const AdvisorTextMobile = styled(Text)(
  mediaQuery({
    width: 190,
    height: 100,
    padding: '20px 0 0 22px',
    fontSize: 14,
    textAlign: 'left',
    lineHeight: '19px',
    background: `url(${copyBgHorizontal}) no-repeat`,
    backgroundSize: 'contain',
    animation: `${bounceIn} 3s ease 1`,
    '&.advisor-text-bounce': {animation: `${bounceIn} 2s ease 1`},
    '&.advisor-text-swing': {
      transformOrigin: 'top center',
      animation: `${swing6} 2s linear 1`,
    },
  }),
);
