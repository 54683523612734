import {gql} from '@apollo/client';

export const PRICE_SCENARIO = gql`
  query priceScenario(
    $afterRenovationValue: Int!
    $creditScore: Int!
    $currentHomeValue: Int!
    $firstMortgageBalance: Int!
    $loanAmount: Int!
    $loanPurpose: String!
    $occupancyType: String!
    $propertyType: String!
    $secondMortgageBalance: Int!
    $zipCode: String!
  ) {
    priceScenario(
      afterRenovationValue: $afterRenovationValue
      creditScore: $creditScore
      currentHomeValue: $currentHomeValue
      firstMortgageBalance: $firstMortgageBalance
      loanAmount: $loanAmount
      loanPurpose: $loanPurpose
      occupancyType: $occupancyType
      propertyType: $propertyType
      secondMortgageBalance: $secondMortgageBalance
      zipCode: $zipCode
    ) {
      adjustedRate
      amortizationPayment
      amortizationType
      arvCltv
      code
      drawTermMonths
      errors
      estimatedApr
      estimatedIoPayment
      estimatedPayment
      id
      loanAmount
      lenderName
      loanTermMonths
      name
      peakCltv
    }
  }
`;
