import castIfNullish from './castIfNullish';
import {getLocation, isDeployedUnderNetlifyDomain} from './environment';

export const getItem = (key) => {
  const value = global?.document?.cookie
    ?.split('; ')
    .reduce((total, currentCookie) => {
      const item = currentCookie.split('=');
      const storedKey = item[0];
      const storedValue = item[1];
      return key === storedKey && storedValue
        ? decodeURIComponent(storedValue)
        : total;
    }, '');

  return castIfNullish(value);
};

export const setItem = ({
  key,
  value,
  numberOfDays = 1,
  suffix = '',
  domain,
}) => {
  const now = new Date();
  // set the time to be now + numberOfDays
  now.setTime(now.getTime() + numberOfDays * 60 * 60 * 24 * 1000);
  document.cookie = `${key}=${value}; ${
    domain && `domain=${domain};`
  } expires=${now.toUTCString()}; path=/;${suffix}`;
};

export function deleteCookie(key) {
  setItem({key, value: '', domain: getDomain(), numberOfDays: -1});
}

export const getDomain = () =>
  isDeployedUnderNetlifyDomain()
    ? undefined
    : getLocation()?.hostname?.split('.').slice(-2).join('.');

export function deleteAllCookies({exceptions = []} = {}) {
  const cookies = document.cookie.split('; ');
  for (let c = 0; c < cookies.length; c++) {
    const isInExceptions = exceptions.find((exception) =>
      cookies[c].includes(exception),
    );
    const isRenofiCookie =
      cookies[c].includes('renofi') || cookies[c].includes('jwt');

    if (isInExceptions || !isRenofiCookie) continue;

    const d = window?.location?.hostname?.split('.').slice(-2);
    while (d?.length > 0) {
      const cookieBase =
        encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
        '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
        d.join('.') +
        ' ;path=';

      const p = window.location.pathname.split('/');
      document.cookie = cookieBase + '/';
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/');
        p.pop();
      }
      d.shift();
    }
  }
}
