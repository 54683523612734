import React, {useRef} from 'react';

import PropTypes from 'prop-types';

import {useAutofocus} from '@renofi/utils';

import {Input} from './MessageInput.styled';
import calculateTotalLines from './utils';

const MessageInput = ({onChange, onEnterPress, value}) => {
  const ref = useRef();

  useAutofocus(true, ref, value);

  const onKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onEnterPress();
    }
  };

  return (
    <Input
      rows={1}
      id="renochat-message-input"
      ref={ref}
      onKeyDown={onKeyDown}
      linesCount={calculateTotalLines({
        id: 'renochat-message-input',
        offset: 2,
      })}
      placeholder="Write a reply..."
      onChange={onChange}
      value={value}
    />
  );
};

MessageInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onEnterPress: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default MessageInput;
