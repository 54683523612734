import {gql} from '@apollo/client';

import PRODUCT from '../partials/product';
import LEAD from '../partials/lead';

export const RENOVATION_SCENARIO = gql`
  query renovationScenario($id: ID, $token: String) {
    result: renovationScenario(id: $id, token: $token) {
      crmOwner {
        firstName
        lastName
        nmlsId
        phoneNumber
        photoUrl
      }
      leadPersonalAttributes {
          city
          county
          email
          lastName
          lat
          lng
          phone
          streetAddress
          zipCode
      }
      lead {
        ${LEAD}
      }
      recommendedLender {
        name
        code
        logoUrl
      }
      preferredLender {
        name
        code
        logoUrl
      }
      calculatorLoanProducts {
        ${PRODUCT}
        variants {
          id
          rates
          repaymentPeriod
        }
      }
      preferredProductMatch {
        maxLoanAmountOffered
        minMonthlyPayment
        refinancedMonthlyMortgagePayments
        renovationFractionMinMonthlyPayment
        offeredLoanAmountExcludingRefinance
        product {
          ${PRODUCT}
          variants {
              id
              rates
              repaymentPeriod
          }
        }
      }
      loanProductMatches {
        maxLoanAmountOffered
        minMonthlyPayment
        refinancedMonthlyMortgagePayments
        renovationFractionMinMonthlyPayment
        offeredLoanAmountExcludingRefinance
        product {
          ${PRODUCT}
        }
      }
      loanProductMatchErrors
      isPeak
      positionedInterestRate
    }
  }
`;

export const LENDING_TREE_PRODUCTS = gql`
  query renovationScenario($id: ID, $token: String) {
    result: renovationScenario(id: $id, token: $token) {
      loanPassMatches {
        id
        productType
        loanAmount
        estimatedApr
        estimatedPayment
        interestOnlyPeriod
        loanTermMonths
        amortizationType
      }
    }
  }
`;
