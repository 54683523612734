import {gql} from '@apollo/client';

export default gql`
  query consolidatedEntities(
    $filterBy: ConsolidatedEntityFilterInputObject!
    $limit: Int
    $page: Int
    $query: String
    $sortBy: ConsolidatedEntitySortInputObject
  ) {
    consolidatedEntities(
      filterBy: $filterBy
      limit: $limit
      page: $page
      query: $query
      sortBy: $sortBy
    ) {
      collection {
        contractorId
        createdAt
        entityDesignStatus
        entityFullName
        entityId
        entityLeadStatus
        entityProjectActionStatus
        entityProjectStatus
        entityPropertyAddress
        entityRenovationCost
        id
        normalizedEntityType
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;
