import {gql} from '@apollo/client';

export default gql`
  query productMatchesForEstimate(
    $zipCode: String!
    $estimatedHomeValue: Int
    $loanAmount: Int!
  ) {
    productMatchesForEstimate(
      zipCode: $zipCode
      estimatedHomeValue: $estimatedHomeValue
      loanAmount: $loanAmount
    ) {
      minMonthlyPayment
      maxLoanAmountOffered
      product {
        repaymentPeriod
        drawPeriod
        interestOnly
        terms
        refinanceRequired
        maxLoanAmount
        rateType
        rates
        name
        cashDisbursementType
      }
    }
  }
`;
