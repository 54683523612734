import React, {memo} from 'react';

import PropTypes from 'prop-types';

import {gray} from '@renofi/utils/src/colors';

import Loader from '../Loader';

import {ReactComponent as TickIcon} from './tick-icon.svg';
import {StyledButton, IconWrapper} from './styled';

const Button = ({success, loading, icon, children, css, ...props}) => {
  const Icon = icon || null;
  const hideBody = Boolean(loading && props.xxSmall);
  return (
    <>
      <StyledButton
        {...props}
        loading={loading ? 'true' : undefined}
        css={css}
        withIcon={(loading && !props.xxSmall) || icon || success}>
        {hideBody ? '' : children}
        {loading ? (
          <IconWrapper {...props} center={hideBody}>
            <Loader color={props.disabled ? gray : 'white'} />
          </IconWrapper>
        ) : null}
        {!loading && success ? (
          <IconWrapper {...props}>
            <TickIcon style={{margin: '0 10px 0 -5px', color: 'red'}} />
          </IconWrapper>
        ) : null}
        {icon && !loading && !success && (
          <IconWrapper {...props}>
            <Icon height={20} />
          </IconWrapper>
        )}
      </StyledButton>
    </>
  );
};

Button.propTypes = {
  secondary: PropTypes.bool,
  transparent: PropTypes.bool,
  disabled: PropTypes.bool,
  success: PropTypes.bool,
  loading: PropTypes.bool,
  small: PropTypes.bool,
  xSmall: PropTypes.bool,
  xxSmall: PropTypes.bool,
  large: PropTypes.bool,
  noCaps: PropTypes.bool,
  icon: PropTypes.object,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  borderColor: PropTypes.string,
  disabledBgColor: PropTypes.string,
  disabledColor: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  css: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default memo(Button);
