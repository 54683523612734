import {makeVar, useReactiveVar} from '@apollo/client';
import {uniq, without} from 'lodash';

export const queriesVar = makeVar([]);
/**
 * Don't check for duplicates when adding, 'cos you could have many
 * requests hitting the same Query (but with different return objects)
 * So we want to track ALL instances of myQuery() currently active.
 */
export const toggleSystemQuery = (queryName, add) => {
  const queries = queriesVar();
  const updated = add ? queries.concat(queryName) : without(queries, queryName);
  queriesVar(uniq(updated));
};

const useWatchApolloQueries = () => {
  const queries = useReactiveVar(queriesVar);

  return {
    queries,
    toggleSystemQuery,
  };
};

export default useWatchApolloQueries;
