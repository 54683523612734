import {gql} from '@apollo/client';

export default gql`
  mutation setDocumentType($documentType: DocumentType!, $id: ID!) {
    setDocumentType(documentType: $documentType, id: $id) {
      document {
        id
        acceptedById
        createdAt
        documentType
        fileName
        fileSizeBytes
        objectName
        rejectedById
        source
      }
    }
  }
`;
