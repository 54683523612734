import {isEqual, pick} from 'lodash';

const jpgExts = ['jpg', 'jpeg', 'jpe', 'jif', 'jfif', 'jfi'];

const imageExts = [
  ...jpgExts,
  'png',
  'bmp',
  'gif',
  'tif',
  'tiff',
  'svg',
  'webp',
  'jp2',
  'mj2',
  'j2k',
  'jpf',
  'jpx',
  'jpm',
];

const textExts = ['csv', 'log', 'txt'];

const powerPointExts = ['ppt', 'pptx'];
const slidesExts = ['odp', powerPointExts];

const excelExts = ['xls', 'xlsx'];
const sheetExts = ['ods', excelExts];

const wordExts = ['doc', 'docx'];
const documentExts = [wordExts, 'odt'];

const officeExts = [...slidesExts, ...sheetExts, ...documentExts];

const archiveExts = ['7z', 'bz2', 'gz', 'rar', 'tar', 'xz', 'z', 'zip'];

const getFileExtension = (fileName) => {
  const arr = fileName.split('.');
  const extension = arr[arr.length - 1];
  return extension.toLowerCase();
};

const getFileType = (fileName) => {
  const extension = getFileExtension(fileName);
  return {
    extension,
    archive: archiveExts.includes(extension),
    image: imageExts.includes(extension),
    office: officeExts.includes(extension),
    pdf: extension === 'pdf',
    text: textExts.includes(extension),
    excel: excelExts.includes(extension),
    powerPoint: powerPointExts.includes(extension),
    word: wordExts.includes(extension),
    rar: extension === 'rar',
    zip: extension === 'zip',
    jpg: jpgExts.includes(extension),
    bmp: extension === 'bmp',
    png: extension === 'png',
    svg: extension === 'svg',
  };
};

export const compareKeys = (keys, prev, next) => {
  const prevProps = pick(keys, prev);
  const nextProps = pick(keys, next);
  return isEqual(prevProps, nextProps);
};

export const isImage = (fileName) => {
  if (!fileName) return false;
  const {image} = getFileType(fileName);
  return image;
};
