import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import {useScreenSize, getBody} from '@renofi/utils';

import {CloseIcon} from '../Icons';

import {
  Cover,
  ModalWrapper,
  ModalContainer,
  Header,
  Content,
  Close,
} from './styled';
const winBody = getBody();
const original = winBody?.style.overflow;
const originalPaddingBottom = winBody?.style.paddingBottom;

const Modal = ({
  show,
  onClose,
  title,
  children,
  hideCloseButton,
  css,
  contentCss,
  width,
  light,
  id = 'root',
  backButton = null,
}) => {
  const {isMobile} = useScreenSize();
  const showHeader = title || !hideCloseButton;

  useEffect(() => {
    const overflow = isMobile && show ? 'hidden' : original;
    const position = isMobile && show ? 'fixed' : 'initial';
    const width = isMobile && show ? '100%' : 'auto';
    const offset = winBody?.scrollTop;
    const paddingBottom = isMobile && show ? '0' : originalPaddingBottom;
    winBody.style.top = offset * -1 + 'px';
    winBody.style.overflow = overflow;
    winBody.style.position = position;
    winBody.style.width = width;
    winBody.style.paddingBottom = paddingBottom;

    return () => {
      winBody.style.overflow = original;
      winBody.style.position = 'initial';
      winBody.style.width = 'auto';
      winBody.style.paddingBottom = originalPaddingBottom;
    };
  }, [show]);

  if (!show) return null;

  return ReactDOM.createPortal(
    <>
      <Cover visible={show} />
      <ModalWrapper visible={show} css={css}>
        <ModalContainer width={width}>
          {showHeader ? (
            <Header light={light} title={title}>
              {backButton}
              {title || <span />}
              {hideCloseButton ? null : (
                <Close onClick={onClose} light={light}>
                  <CloseIcon />
                </Close>
              )}
            </Header>
          ) : null}

          <Content fullRadius={!title} css={contentCss} light={light}>
            {children}
          </Content>
        </ModalContainer>
      </ModalWrapper>
    </>,
    document.getElementById(id) || document.createElement('div'),
  );
};

Modal.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hideCloseButton: PropTypes.bool,
  light: PropTypes.bool,
  onClose: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  css: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  contentCss: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  id: PropTypes.string,
  backButton: PropTypes.element,
};

export default Modal;
