import gcddReview from './gcddReview';
import questionnaire from './questionnaire';

export default `
  id
  businessName
  city
  createdById
  daasToken
  gcddReviews {
    ${gcddReview}
  }
  latestQuestionnaire {
    ${questionnaire}
  }
  logoUrl
  marketingAssets {
    assetType
    createdAt
    id
    objectName
    publicUrl
    updatedAt
  }
  questionnaires {
    ${questionnaire}
  }
  secondaryAddress
  state
  streetAddress
  urlSlug
  website
  zipCode
`;
