import {gql} from '@apollo/client';

import {contractorEstimate as estimate} from '../../partials';

export default gql`
  query contractorEstimates($page: Int, $limit: Int) {
    contractorEstimates(page: $page, limit: $limit) {
      collection {
        ${estimate}
      },
      metadata {
        currentPage
        totalPages
      }
    }
  }
`;
