import React from 'react';

import PropTypes from 'prop-types';

const MarkdownLink = ({href, children}) => {
  const specialLinkRegex = /design|prequal/;
  const isSpecialLink = specialLinkRegex.test(href || '');

  const className = isSpecialLink
    ? 'renochat-special-link'
    : 'renochat-regular-link';

  return (
    <a
      href={href}
      className={className}
      target="_blank"
      rel="noopener noreferrer">
      {children}
    </a>
  );
};

MarkdownLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
};

export default MarkdownLink;
