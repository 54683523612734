import {gql} from '@apollo/client';

export default gql`
  query gcddReviewsByContractorId(
    $contractorId: ID!
    $limit: Int
    $page: Int
    $sortBy: GcddReviewSortInputObject
  ) {
    result: gcddReviewsByContractorId(
      contractorId: $contractorId
      limit: $limit
      page: $page
      sortBy: $sortBy
    ) {
      collection {
        id
        assignedToId
        assignedTo {
          email
          firstName
          id
          lastName
          phoneNumber
        }
        cancelledAt
        cancellationMessage
        cancellationReason
        completedAt
        complexityLevel
        contractor {
          businessName
          id
        }
        contractorInformationId
        estimatedCostOfWork
        overallScore
        overallScoreNotes
        priority
        projectId
        questionnaire {
          contractorGeneralLiabilityInsurance {
            reviewResult
          }
          contractorLicense {
            reviewResult
          }
          contractorWorkersCompInsurance {
            reviewResult
          }
          createdAt
          firstOpenedAt
          hasWorkersCompInsurance
          returnedAt
          reviewedAt
          score
          submittedStep
        }
        returnedAt
        scopeOfWork
        webReviewsNotFound
        webReviewsScore
        webReviewsScoreNotes
        webReviewsScoreSubmittedAt
        webReviewsScoreSubmittedById
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;
