import {gql} from '@apollo/client';

export const SUBMIT_CONTACT = gql`
  mutation submitContact(
    $email: String!
    $firstName: String!
    $lastName: String!
    $phone: String!
    $creditScore: CreditScoreEnum!
  ) {
    submitContact(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      creditScore: $creditScore
    ) {
      error
      lead {
        email
        id
        firstName
        lastName
        creditScore
      }
    }
  }
`;
