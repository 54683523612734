import {gql} from '@apollo/client';

import {questionnaire} from '../../partials';

export const QUESTIONNAIRE_BY_TOKEN = gql`
  query questionnaireByToken($accessTo: AccessToForTokenEnum, $token: String!) {
    result: questionnaireByToken(accessTo: $accessTo, token: $token) {
      ${questionnaire}
    }
  }
`;
