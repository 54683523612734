import {gql} from '@apollo/client';

export const REMOVE_QUESTIONNAIRE_DOCUMENT = gql`
  mutation removeQuestionnaireDocument(
    $documentType: DocumentTypeEnum!
    $questionnaireId: ID
    $token: String
  ) {
    removeQuestionnaireDocument(
      documentType: $documentType
      questionnaireId: $questionnaireId
      token: $token
    ) {
      questionnaire {
        id
      }
    }
  }
`;
