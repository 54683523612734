import {gql} from '@apollo/client';

export const SIGN_QUESTIONNAIRE = gql`
  mutation signQuestionnaire(
    $signatoryBusinessName: String!
    $signatoryName: String!
    $signatoryTitle: String
    $signatureDate: Date!
    $questionnaireId: ID
    $token: String
  ) {
    signQuestionnaire(
      signatoryBusinessName: $signatoryBusinessName
      signatoryName: $signatoryName
      signatoryTitle: $signatoryTitle
      signatureDate: $signatureDate
      questionnaireId: $questionnaireId
      token: $token
    ) {
      questionnaire {
        id
      }
    }
  }
`;
