import styled from '@emotion/styled/macro';
import {Flex, Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {darkBlue} from '@renofi/utils/src/colors';

import arrowDesktop from './img/arrow-desktop.svg';
import arrowMobile from './img/arrow-mobile.png';
import arrowMobileWhite from './img/arrow-mobile-white.png';
import arrowWhite from './img/arrow-white.svg';

export const StepsWrapper = styled(Flex)(
  mediaQuery({
    flexDirection: ['column', 'row'],
    borderRadius: 15,
    marginTop: [40, 65],
    marginBottom: [0, 30],
  }),
  ({css, blue}) =>
    mediaQuery({
      background: blue ? '#f2f7fa' : '#dcf2df',
      'svg .background': {
        fill: blue ? '#CCDFEB' : '#C1D6C9',
      },
      ...css,
    }),
);

export const StepWrapper = styled(Flex)(
  mediaQuery({
    position: 'relative',
    padding: '0 20px',
    alignItems: ['flex-start', 'center'],
    justifyContent: ['flex-end', 'center'],
    flexDirection: ['row', 'column'],
    marginBottom: [20, 0],
    marginTop: [0, 0],
    marginRight: [0, 10],
    paddingBottom: 10,
    paddingTop: [20, 0],
    ':last-of-type': {
      marginRight: 0,
      marginBottom: 0,
    },
    ':last-of-type:after': {
      display: 'none',
    },
    ':after': {
      content: '" "',
      position: 'absolute',
      zIndex: 100,
      right: ['inherit', -20],
      left: [0, 'inherit'],
      bottom: [-20, 0],
      width: ['100%', 36],
      height: [30, '100%'],
    },
  }),
  ({blue}) =>
    mediaQuery({
      ':after': {
        background: [
          `url(${blue ? arrowMobileWhite : arrowMobile}) no-repeat`,
          `url(${blue ? arrowWhite : arrowDesktop}) no-repeat`,
        ],
        backgroundSize: ['100% 28px', 'cover'],
      },
    }),
);

export const StepTitle = styled(Text)(
  mediaQuery({
    width: '100%',
    marginTop: [10, 0],
    color: darkBlue,
    fontSize: [24, 16],
    fontWeight: 300,
    textAlign: 'center',
    lineHeight: ['28px', '20px'],
  }),
);

export const IconWrapper = styled(Flex)(
  mediaQuery({
    width: [130, 'auto'],
    marginTop: [0, -70],
    marginBottom: 10,
    marginRight: [20, 0],
    height: ['auto', 100],
    alignItems: 'flex-end',
  }),
);
