import styled from '@emotion/styled/macro';
import {Textarea as RebassTextarea} from '@rebass/forms';

import {darkGray, lightRed} from '@renofi/utils';

export const Textarea = styled(RebassTextarea)(({error}) => {
  const borderColor = error ? lightRed : '#DCDCDC';

  return {
    color: darkGray,
    border: `solid 2px ${borderColor}`,
    padding: '10px 13px',
    borderRadius: 8,
    minHeight: 90,
    background: 'white',
    '::placeholder': {
      color: '#DCDCDC',
    },
    ':visited, :active, :focus': {
      outline: 'none',
    },
  };
});
