import styled from '@emotion/styled/macro';

import {mediaQuery} from '@renofi/utils';

import awardsIcon from './img/awards-icon.svg';

export const Footer = styled.div(
  mediaQuery({
    overflowAnchor: 'none',
    borderRadius: '0 0 16px 16px',
    margin: '25px -15px -15px -15px',
    padding: ['30px 15px 40px 15px', '30px 110px 40px 110px'],
    borderTop: 'solid 1px white',
  }),
  ({height, hideAward, top, css}) =>
    mediaQuery({
      height: height ? `${height}px` : 'auto',
      background: hideAward
        ? '#F8F8F8'
        : `#F8FAFC url(${awardsIcon}) no-repeat`,
      backgroundPosition: [
        'center bottom 30px',
        `right 115px top ${top ? top : 35}px`,
      ],
      ...css,
    }),
);
