export const fadeBlue = '#E5F5FF';
export const lightBlue = '#80CFFF';
export const blue = '#00A0FF';
export const darkBlue = '#212F49';

export const fadeGreen = '#E7F9E9';
export const lightGreen = '#89E193';
export const green = '#0CA61D';
export const darkGreen = '#0B7517';

export const fadeGray = '#f3f3f3';
export const lightGray = '#CCCCCC';
export const gray = '#737373';
export const blueGray = '#D9E7F0';
export const darkGray = '#333333';

export const fadeRed = '#FFEEEE';
export const lightRed = '#FFA8A8';
export const red = '#FF5253';
export const darkRed = '#993131';

export const fadeYellow = '#FFF7E9';
export const lightYellow = '#FFD997';
export const yellow = '#FFB430';
export const darkYellow = '#996C1C';

export default {
  blue,
  lightBlue,
  blueGray,
  green,
  lightGreen,
  fadeGray,
  red,
  yellow,
};
