import React from 'react';

import {animated, useSpring} from 'react-spring';
import PropTypes from 'prop-types';

import {Button} from '@renofi/components';
import {fast} from '@renofi/utils/src/animation';

import {ReactComponent as HideIcon} from './img/hide.svg';
import FaceIcon from './FaceIcon';

const ToggleButton = ({status, onOpen, onClose}) => {
  const [active, setActive] = React.useState(false);
  const props = useSpring({
    transform: active ? 'scale(1.1)' : 'scale(1)',
    config: fast,
  });

  return (
    <Button
      transparent
      css={{
        padding: 0,
        position: 'absolute',
        right: 12,
        bottom: 8,
        width: 'auto',
      }}
      onMouseOver={() => setActive(true)}
      onMouseOut={() => setActive(false)}
      onClick={status !== 'open' ? onOpen : onClose}>
      <animated.div style={{transform: props.transform}}>
        {status !== 'open' ? <FaceIcon animate /> : <HideIcon />}
      </animated.div>
    </Button>
  );
};

ToggleButton.propTypes = {
  status: PropTypes.string.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ToggleButton;
