const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const formatDayOfWeek = (value) => {
  const date = value instanceof Date ? value : new Date(value);
  const dayIndex = date.getDay();
  return days[dayIndex];
};

export const formatMonth = (value, short = false) => {
  const date = value instanceof Date ? value : new Date(value);
  const monthIndex = date.getMonth();
  const month = months[monthIndex];
  return short ? month?.substring(0, 3) : month;
};

export const formatAMPM = (value, {separator = ':', upper = false} = {}) => {
  const date = value instanceof Date ? value : new Date(value);
  const minutes = `${date.getUTCMinutes()}`.padStart(2, '0');
  const hours = date.getUTCHours();
  const remainder = hours % 12;
  const finalHours = Boolean(remainder) ? `${remainder}`.padStart(2, '0') : 12;
  const amPm = hours >= 12 ? 'pm' : 'am';

  return `${finalHours}${separator}${minutes}${upper ? amPm.toUpperCase() : amPm}`;
};

export function formatDate(date, options = {}) {
  const monthIndex = date.getMonth();
  const dayIndex = date.getDay();
  const fullYear = date.getFullYear();
  const day = date.getDate();
  const monthName = months[monthIndex];
  const dayName = days[dayIndex];

  if (options.numeric) {
    return `${monthIndex + 1}/${day}/${fullYear}`;
  }

  return `${dayName}, ${day} ${monthName}`;
}

export const formatIsoDate = (
  isoDateString,
  {separator = '/', includeTime = false} = {},
) => {
  if (!isoDateString) return null;

  const date = new Date(isoDateString);

  const day = `${date.getUTCDate()}`.padStart(2, '0');
  const month = `${date.getUTCMonth() + 1}`.padStart(2, '0');
  const year = date.getUTCFullYear();
  const time = `${date.getUTCHours()}${separator}${date.getUTCMinutes()}`;
  const parts = includeTime ? [month, day, year, time] : [month, day, year];

  return parts.join(separator);
};

// converts date to 'yyyy-mm-dd' format
export function convertDateToString(date, separator = '-', USFormat) {
  if (!date) return date;

  let month = '' + (date.getMonth() + 1),
    day = '' + date.getDate(),
    year = '' + date.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  if (USFormat) return [month, day, year].join(separator);
  return [year, month, day].join(separator);
}

export function splitDateString(stringDate = '') {
  return {
    month: stringDate.slice(0, 2),
    day: stringDate.slice(2, 4),
    year: stringDate.slice(4),
  };
}

export function millisecondsToHumanReadableForm(milliseconds) {
  function numberEnding(number) {
    return number > 1 ? 's' : '';
  }

  let seconds = Math.floor(milliseconds / 1000);

  let days = Math.floor((seconds %= 31536000) / 86400);
  if (days) {
    return days + ' day' + numberEnding(days);
  }

  let hours = Math.floor((seconds %= 86400) / 3600);
  if (hours) {
    return hours + ' hour' + numberEnding(hours);
  }

  let minutes = Math.floor((seconds %= 3600) / 60);
  if (minutes) {
    return minutes + ' minute' + numberEnding(minutes);
  }

  return null;
}

export const getTimePassedFromDateTillNow = (date) => {
  const now = new Date();
  const diff = millisecondsToHumanReadableForm(now - new Date(date));
  return diff === null ? 'just now' : `${diff} ago`;
};
