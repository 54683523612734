import {gql} from '@apollo/client';

import {borrowersAndPropertyInformation} from '../../partials';

export default gql`
  query contractorProjectsV2 {
    contractorProjectsV2 {
      borrowersAndPropertyInformation {
        ${borrowersAndPropertyInformation}
      }
      renovationCost
      createdAt
      documents {
        id
        acceptedById
        createdAt
        documentType
        fileName
        fileSizeBytes
        isRemovable
        objectName
        rejectedById
        source
      }
      feasibilityReviews {
        id
        status
      }
      gcddReviewStatus
      id
      loanTypeEnum {
        crmName
        isPersonal
      }
      stage {
        crmName
        name
      }
      status
      updatedAt
    }
  }
`;
