import {gql} from '@apollo/client';

export const CONTRACTOR_FIND = gql`
  query contractorFind(
    $query: String
    $filter: GcddReviewSearchFilterInputObject
  ) {
    contractorFind(query: $query, filter: $filter) {
      bbbGrade
      bbbGradeChecked
      businessName
      city
      hasBeenInvolvedInLawsuit
      id
      insuranceScore
      licenseScore
      overallScore
      referencesScore
      state
      website
    }
  }
`;
