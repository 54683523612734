import {gql} from '@apollo/client';

export const REQUEST_EMAIL_ADDRESS_CHANGE = gql`
  mutation requestEmailAddressChange(
    $emailToConfirm: String!
    $questionnaireId: ID
    $token: String!
  ) {
    requestEmailAddressChange(
      emailToConfirm: $emailToConfirm
      questionnaireId: $questionnaireId
      token: $token
    ) {
      success
    }
  }
`;
