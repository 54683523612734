import styled from '@emotion/styled';

import {gray, lightGray} from '@renofi/utils';

const lineHeight = 20;

export const Input = styled.textarea(({linesCount}) => ({
  resize: 'none',
  border: 'none',
  height: linesCount > 1 ? 60 + (linesCount - 1) * lineHeight : 60,
  width: '100%',
  lineHeight: `${lineHeight}px`,
  padding: '18px 24px',
  fontSize: 14,
  color: gray,
  borderBottomRightRadius: 20,
  borderBottomLeftRadius: 20,
  ':visited, :active, :focus': {
    outline: 'none',
  },
  '::placeholder': {
    color: lightGray,
  },
}));
