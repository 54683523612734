import {last} from 'lodash';

import {usePrevious} from '@renofi/utils';

import {easePolyOut, easeSinOut, easePolyInOut} from './easy';

export function useBreakpoint(value, max) {
  const placeholder = Array.from(Array(6).keys());
  const breakpoints = placeholder.map((item) => item * (max / 6));
  const breakpoint = breakpoints.findIndex((step) => value <= step);

  const prevValue = usePrevious(value);
  const isReturning = value < prevValue;

  return [breakpoint, isReturning];
}

export const find = (array, breakpoint) => {
  if (breakpoint < 0) return last(array);
  if (array[breakpoint] === 0) return 0;
  return array[breakpoint] || last(array);
};

export const superFast = {
  duration: 100,
};

export const fast = {
  duration: 120,
  clamp: true,
  easing: easeSinOut,
};

export const slow = {
  duration: 420,
  clamp: true,
  easing: easePolyInOut,
};

export const slow2 = {
  duration: 320,
  clamp: true,
  easing: easePolyInOut,
};

export const slide = {
  duration: 360,
  clamp: true,
  easing: easePolyOut,
};

export const easyIn = {
  mass: 0.1,
  tension: 380,
  friction: 27,
};

export const jumpIn = {
  mass: 1,
  tension: 450,
  friction: 25,
};

export const fadeIn = {
  from: {opacity: 0},
  to: {opacity: 1},
  delay: 300,
};
