import {gql} from '@apollo/client';

export default gql`
  mutation generateShortUrl($contractorId: ID, $url: Url!) {
    generateShortUrl(contractorId: $contractorId, url: $url) {
      link {
        mainUrl
        shortCode
      }
    }
  }
`;
