import {gql} from '@apollo/client';

import PRODUCT from '../partials/product';

export const TOP_LOAN_PRODUCTS = gql`
    query topLoanProducts {
        topLoanProducts {
            ${PRODUCT}   
        }
    }
`;
