import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {
  darkBlue,
  darkGreen,
  darkRed,
  darkYellow,
  fadeBlue,
  fadeGreen,
  fadeRed,
  fadeYellow,
  lightRed,
} from '@renofi/utils/src/colors';
export const AlertWrapper = styled(Flex)(
  mediaQuery({
    width: '100%',
    textAlign: ['center', 'left'],
    lineHeight: '18px',
    marginTop: 15,
    borderRadius: 6,
  }),
  ({border, bgColor, color, fontSize, large, success, warning, info, css}) =>
    mediaQuery({
      border: border ? border : `solid 1px ${lightRed}`,
      borderColor: (() => {
        if (border) return null;
        if (info) return fadeBlue;
        if (success) return fadeGreen;
        return lightRed;
      })(),
      background: (() => {
        if (bgColor) return bgColor;
        if (warning) return fadeYellow;
        if (success) return fadeGreen;
        if (info) return fadeBlue;
        return fadeRed;
      })(),
      fontSize: fontSize ? fontSize : large ? 16 : 14,
      lineHeight: fontSize ? `${fontSize + 2}px` : large ? '20px' : '16px',
      padding: large ? '17px 17px' : '12px 17px',
      'svg path': {
        fill: (() => {
          if (color) return color;
          if (warning) return darkYellow;
          if (info) return darkBlue;
          if (success) return darkGreen;
          return darkRed;
        })(),
      },
      ...css,
    }),
);

export const AlertContent = styled(Flex)(
  mediaQuery({
    paddingLeft: 0,
  }),
  ({color, alignItems, success, warning, info}) => ({
    color: (() => {
      if (color) return color;
      if (warning) return darkYellow;
      if (info) return '#006099';
      if (success) return darkGreen;
      return darkRed;
    })(),
    alignItems: alignItems ? alignItems : 'flex-start',
  }),
);
