import styled from '@emotion/styled';
import {Box} from 'rebass';

export const Wrapper = styled(Box)(
  {
    height: 'calc(100%)',
    backgroundColor: 'transparent',
    minHeight: 400,
  },
  ({width}) => ({width}),
);
