import {omit, isNil, findKey} from 'lodash';
import {clone, setWith, curry} from 'lodash/fp';

export function filterUnchangedKeys(obj1, obj2) {
  const equals = Object.keys(obj1).filter((key) => {
    return isNil(obj1[key]) || (!isNil(obj2[key]) && obj1[key] === obj2[key]);
  });
  return omit(obj1, equals);
}

export function mapObjectValues(object, fn) {
  return Object.fromEntries(
    Object.entries(object).map(([k, v], i) => [k, fn(k, v, i)]),
  );
}

export function filterEmptyValues(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => isNil(v) || v === ''),
  );
}

export function filterTruthyValues(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v));
}

export function getKey(value, options) {
  return findKey(options, (item) => item === value);
}

export const setIn = curry((obj, path, value) =>
  setWith(clone, path, value, clone(obj)),
);
