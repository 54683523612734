import {gql} from '@apollo/client';

export const ELIGIBLE_ZIP_CODE = gql`
  query eligibleZipCode($zipCode: String!) {
    eligibleZipCode(zipCode: $zipCode) {
      city
      state
    }
  }
`;
