import {useEffect} from 'react';

function useClickOutside(handler, ref) {
  const handleClickOutside = (event) => {
    if (ref?.current && !ref.current.contains(event.target)) {
      handler();
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [handler, ref]);

  return [ref];
}

export default useClickOutside;
