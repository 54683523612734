import {gql} from '@apollo/client';

export const SUBMIT_CONTRACTOR_SEARCH = gql`
  mutation submitContractorSearch(
    $businessName: String!
    $contractorId: ID
    $email: String
    $phoneNumber: String
    $scenarioId: ID!
    $state: String
    $website: String
  ) {
    submitContractorSearch(
      businessName: $businessName
      contractorId: $contractorId
      email: $email
      phoneNumber: $phoneNumber
      scenarioId: $scenarioId
      state: $state
      website: $website
    ) {
      success
    }
  }
`;
